@charset "UTF-8";
/* Slider */
@import url(../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css);
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: Arial;
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: Arial;
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img,
ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time,
mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

html {
  overflow-x: hidden; }

body {
  line-height: 1; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

ol, ul {
  list-style: none; }

strong, b {
  font-weight: bold; }

input:focus {
  outline: none; }

blockquote, q {
  quotes: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a[ng-click], div[ng-click], p[ng-click], span[ng-click], img[ng-click], a[ui-sref], div[ui-sref], p[ui-sref], span[ui-sref], img[ui-sref] {
  cursor: pointer; }

input::-ms-clear {
  display: none; }

input[type="search"] {
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  -webkit-border-radius: 0; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none; }

sup {
  margin-left: 5px;
  font-size: 0.7em;
  bottom: 1ex;
  vertical-align: 0;
  position: relative; }

button {
  border: none;
  cursor: pointer; }

textarea:hover, input:hover, textarea:active, input:active, textarea:focus, input:focus, button:focus, button:active, button:hover, label:focus, .btn:active, .btn.active {
  outline: 0px !important;
  -webkit-appearance: none; }

.slick-slide, .slick-slide a, .slick-slide:focus {
  outline: none !important; }

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus input:-webkit-autofill, textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important; }

a {
  text-decoration: none;
  color: inherit;
  -webkit-tap-highlight-color: transparent; }

@-webkit-keyframes autofill {
  100% {
    background: transparent; } }

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both; }

.row.fixPadding {
  width: calc(100% + 0 + 0);
  margin-left: 0;
  margin-right: 0; }

.row:after {
  content: '';
  display: block;
  clear: both; }

.col1 {
  width: 4.16667%; }

.col2 {
  width: 8.33333%; }

.col3 {
  width: 12.5%; }

.col4 {
  width: 16.66667%; }

.col5 {
  width: 20.83333%; }

.col6 {
  width: 25%; }

.col7 {
  width: 29.16667%; }

.col8 {
  width: 33.33333%; }

.col9 {
  width: 37.5%; }

.col10 {
  width: 41.66667%; }

.col11 {
  width: 45.83333%; }

.col12 {
  width: 50%; }

.col13 {
  width: 54.16667%; }

.col14 {
  width: 58.33333%; }

.col15 {
  width: 62.5%; }

.col16 {
  width: 66.66667%; }

.col17 {
  width: 70.83333%; }

.col18 {
  width: 75%; }

.col19 {
  width: 79.16667%; }

.col20 {
  width: 83.33333%; }

.col21 {
  width: 87.5%; }

.col22 {
  width: 91.66667%; }

.col23 {
  width: 95.83333%; }

.col24 {
  width: 100%; }

.col, .col1, .col2, .col3, .col4, .col5, .col6, .col7, .col8, .col9, .col10, .col11, .col12,
.col13, .col14, .col15, .col16, .col17, .col18, .col19, .col20, .col21, .col22, .col23, .col24 {
  display: block;
  float: left;
  min-height: 1px;
  padding: 0 0; }

@media screen and (max-width: 1800px) {
  .col1_1800 {
    width: 4.16667%; }
  .col2_1800 {
    width: 8.33333%; }
  .col3_1800 {
    width: 12.5%; }
  .col4_1800 {
    width: 16.66667%; }
  .col5_1800 {
    width: 20.83333%; }
  .col6_1800 {
    width: 25%; }
  .col7_1800 {
    width: 29.16667%; }
  .col8_1800 {
    width: 33.33333%; }
  .col9_1800 {
    width: 37.5%; }
  .col10_1800 {
    width: 41.66667%; }
  .col11_1800 {
    width: 45.83333%; }
  .col12_1800 {
    width: 50%; }
  .col13_1800 {
    width: 54.16667%; }
  .col14_1800 {
    width: 58.33333%; }
  .col15_1800 {
    width: 62.5%; }
  .col16_1800 {
    width: 66.66667%; }
  .col17_1800 {
    width: 70.83333%; }
  .col18_1800 {
    width: 75%; }
  .col19_1800 {
    width: 79.16667%; }
  .col20_1800 {
    width: 83.33333%; }
  .col21_1800 {
    width: 87.5%; }
  .col22_1800 {
    width: 91.66667%; }
  .col23_1800 {
    width: 95.83333%; }
  .col24_1800 {
    width: 100%; }
  .col1_1800, .col2_1800, .col3_1800, .col4_1800, .col5_1800, .col6_1800,
  .col7_1800, .col8_1800, .col9_1800, .col10_1800, .col11_1800, .col12_1800,
  .col13_1800, .col14_1800, .col15_1800, .col16_1800, .col17_1800, .col18_1800,
  .col19_1800, .col20_1800, .col21_1800, .col22_1800, .col23_1800, .col24_1800 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 0; } }

@media screen and (max-width: 1700px) {
  .col1_1700 {
    width: 4.16667%; }
  .col2_1700 {
    width: 8.33333%; }
  .col3_1700 {
    width: 12.5%; }
  .col4_1700 {
    width: 16.66667%; }
  .col5_1700 {
    width: 20.83333%; }
  .col6_1700 {
    width: 25%; }
  .col7_1700 {
    width: 29.16667%; }
  .col8_1700 {
    width: 33.33333%; }
  .col9_1700 {
    width: 37.5%; }
  .col10_1700 {
    width: 41.66667%; }
  .col11_1700 {
    width: 45.83333%; }
  .col12_1700 {
    width: 50%; }
  .col13_1700 {
    width: 54.16667%; }
  .col14_1700 {
    width: 58.33333%; }
  .col15_1700 {
    width: 62.5%; }
  .col16_1700 {
    width: 66.66667%; }
  .col17_1700 {
    width: 70.83333%; }
  .col18_1700 {
    width: 75%; }
  .col19_1700 {
    width: 79.16667%; }
  .col20_1700 {
    width: 83.33333%; }
  .col21_1700 {
    width: 87.5%; }
  .col22_1700 {
    width: 91.66667%; }
  .col23_1700 {
    width: 95.83333%; }
  .col24_1700 {
    width: 100%; }
  .col1_1700, .col2_1700, .col3_1700, .col4_1700, .col5_1700, .col6_1700,
  .col7_1700, .col8_1700, .col9_1700, .col10_1700, .col11_1700, .col12_1700,
  .col13_1700, .col14_1700, .col15_1700, .col16_1700, .col17_1700, .col18_1700,
  .col19_1700, .col20_1700, .col21_1700, .col22_1700, .col23_1700, .col24_1700 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 0; } }

@media screen and (max-width: 1600px) {
  .col1_1600 {
    width: 4.16667%; }
  .col2_1600 {
    width: 8.33333%; }
  .col3_1600 {
    width: 12.5%; }
  .col4_1600 {
    width: 16.66667%; }
  .col5_1600 {
    width: 20.83333%; }
  .col6_1600 {
    width: 25%; }
  .col7_1600 {
    width: 29.16667%; }
  .col8_1600 {
    width: 33.33333%; }
  .col9_1600 {
    width: 37.5%; }
  .col10_1600 {
    width: 41.66667%; }
  .col11_1600 {
    width: 45.83333%; }
  .col12_1600 {
    width: 50%; }
  .col13_1600 {
    width: 54.16667%; }
  .col14_1600 {
    width: 58.33333%; }
  .col15_1600 {
    width: 62.5%; }
  .col16_1600 {
    width: 66.66667%; }
  .col17_1600 {
    width: 70.83333%; }
  .col18_1600 {
    width: 75%; }
  .col19_1600 {
    width: 79.16667%; }
  .col20_1600 {
    width: 83.33333%; }
  .col21_1600 {
    width: 87.5%; }
  .col22_1600 {
    width: 91.66667%; }
  .col23_1600 {
    width: 95.83333%; }
  .col24_1600 {
    width: 100%; }
  .col1_1600, .col2_1600, .col3_1600, .col4_1600, .col5_1600, .col6_1600,
  .col7_1600, .col8_1600, .col9_1600, .col10_1600, .col11_1600, .col12_1600,
  .col13_1600, .col14_1600, .col15_1600, .col16_1600, .col17_1600, .col18_1600,
  .col19_1600, .col20_1600, .col21_1600, .col22_1600, .col23_1600, .col24_1600 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 0; } }

@media screen and (max-width: 1500px) {
  .col1_1500 {
    width: 4.16667%; }
  .col2_1500 {
    width: 8.33333%; }
  .col3_1500 {
    width: 12.5%; }
  .col4_1500 {
    width: 16.66667%; }
  .col5_1500 {
    width: 20.83333%; }
  .col6_1500 {
    width: 25%; }
  .col7_1500 {
    width: 29.16667%; }
  .col8_1500 {
    width: 33.33333%; }
  .col9_1500 {
    width: 37.5%; }
  .col10_1500 {
    width: 41.66667%; }
  .col11_1500 {
    width: 45.83333%; }
  .col12_1500 {
    width: 50%; }
  .col13_1500 {
    width: 54.16667%; }
  .col14_1500 {
    width: 58.33333%; }
  .col15_1500 {
    width: 62.5%; }
  .col16_1500 {
    width: 66.66667%; }
  .col17_1500 {
    width: 70.83333%; }
  .col18_1500 {
    width: 75%; }
  .col19_1500 {
    width: 79.16667%; }
  .col20_1500 {
    width: 83.33333%; }
  .col21_1500 {
    width: 87.5%; }
  .col22_1500 {
    width: 91.66667%; }
  .col23_1500 {
    width: 95.83333%; }
  .col24_1500 {
    width: 100%; }
  .col1_1500, .col2_1500, .col3_1500, .col4_1500, .col5_1500, .col6_1500,
  .col7_1500, .col8_1500, .col9_1500, .col10_1500, .col11_1500, .col12_1500,
  .col13_1500, .col14_1500, .col15_1500, .col16_1500, .col17_1500, .col18_1500,
  .col19_1500, .col20_1500, .col21_1500, .col22_1500, .col23_1500, .col24_1500 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 0; } }

@media screen and (max-width: 1400px) {
  .col1_1400 {
    width: 4.16667%; }
  .col2_1400 {
    width: 8.33333%; }
  .col3_1400 {
    width: 12.5%; }
  .col4_1400 {
    width: 16.66667%; }
  .col5_1400 {
    width: 20.83333%; }
  .col6_1400 {
    width: 25%; }
  .col7_1400 {
    width: 29.16667%; }
  .col8_1400 {
    width: 33.33333%; }
  .col9_1400 {
    width: 37.5%; }
  .col10_1400 {
    width: 41.66667%; }
  .col11_1400 {
    width: 45.83333%; }
  .col12_1400 {
    width: 50%; }
  .col13_1400 {
    width: 54.16667%; }
  .col14_1400 {
    width: 58.33333%; }
  .col15_1400 {
    width: 62.5%; }
  .col16_1400 {
    width: 66.66667%; }
  .col17_1400 {
    width: 70.83333%; }
  .col18_1400 {
    width: 75%; }
  .col19_1400 {
    width: 79.16667%; }
  .col20_1400 {
    width: 83.33333%; }
  .col21_1400 {
    width: 87.5%; }
  .col22_1400 {
    width: 91.66667%; }
  .col23_1400 {
    width: 95.83333%; }
  .col24_1400 {
    width: 100%; }
  .col1_1400, .col2_1400, .col3_1400, .col4_1400, .col5_1400, .col6_1400,
  .col7_1400, .col8_1400, .col9_1400, .col10_1400, .col11_1400, .col12_1400,
  .col13_1400, .col14_1400, .col15_1400, .col16_1400, .col17_1400, .col18_1400,
  .col19_1400, .col20_1400, .col21_1400, .col22_1400, .col23_1400, .col24_1400 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 0; } }

@media screen and (max-width: 1300px) {
  .col1_1300 {
    width: 4.16667%; }
  .col2_1300 {
    width: 8.33333%; }
  .col3_1300 {
    width: 12.5%; }
  .col4_1300 {
    width: 16.66667%; }
  .col5_1300 {
    width: 20.83333%; }
  .col6_1300 {
    width: 25%; }
  .col7_1300 {
    width: 29.16667%; }
  .col8_1300 {
    width: 33.33333%; }
  .col9_1300 {
    width: 37.5%; }
  .col10_1300 {
    width: 41.66667%; }
  .col11_1300 {
    width: 45.83333%; }
  .col12_1300 {
    width: 50%; }
  .col13_1300 {
    width: 54.16667%; }
  .col14_1300 {
    width: 58.33333%; }
  .col15_1300 {
    width: 62.5%; }
  .col16_1300 {
    width: 66.66667%; }
  .col17_1300 {
    width: 70.83333%; }
  .col18_1300 {
    width: 75%; }
  .col19_1300 {
    width: 79.16667%; }
  .col20_1300 {
    width: 83.33333%; }
  .col21_1300 {
    width: 87.5%; }
  .col22_1300 {
    width: 91.66667%; }
  .col23_1300 {
    width: 95.83333%; }
  .col24_1300 {
    width: 100%; }
  .col1_1300, .col2_1300, .col3_1300, .col4_1300, .col5_1300, .col6_1300,
  .col7_1300, .col8_1300, .col9_1300, .col10_1300, .col11_1300, .col12_1300,
  .col13_1300, .col14_1300, .col15_1300, .col16_1300, .col17_1300, .col18_1300,
  .col19_1300, .col20_1300, .col21_1300, .col22_1300, .col23_1300, .col24_1300 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 0; } }

@media screen and (max-width: 1200px) {
  .col1_1200 {
    width: 4.16667%; }
  .col2_1200 {
    width: 8.33333%; }
  .col3_1200 {
    width: 12.5%; }
  .col4_1200 {
    width: 16.66667%; }
  .col5_1200 {
    width: 20.83333%; }
  .col6_1200 {
    width: 25%; }
  .col7_1200 {
    width: 29.16667%; }
  .col8_1200 {
    width: 33.33333%; }
  .col9_1200 {
    width: 37.5%; }
  .col10_1200 {
    width: 41.66667%; }
  .col11_1200 {
    width: 45.83333%; }
  .col12_1200 {
    width: 50%; }
  .col13_1200 {
    width: 54.16667%; }
  .col14_1200 {
    width: 58.33333%; }
  .col15_1200 {
    width: 62.5%; }
  .col16_1200 {
    width: 66.66667%; }
  .col17_1200 {
    width: 70.83333%; }
  .col18_1200 {
    width: 75%; }
  .col19_1200 {
    width: 79.16667%; }
  .col20_1200 {
    width: 83.33333%; }
  .col21_1200 {
    width: 87.5%; }
  .col22_1200 {
    width: 91.66667%; }
  .col23_1200 {
    width: 95.83333%; }
  .col24_1200 {
    width: 100%; }
  .col1_1200, .col2_1200, .col3_1200, .col4_1200, .col5_1200, .col6_1200,
  .col7_1200, .col8_1200, .col9_1200, .col10_1200, .col11_1200, .col12_1200,
  .col13_1200, .col14_1200, .col15_1200, .col16_1200, .col17_1200, .col18_1200,
  .col19_1200, .col20_1200, .col21_1200, .col22_1200, .col23_1200, .col24_1200 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 0; } }

@media screen and (max-width: 1100px) {
  .col1_1100 {
    width: 4.16667%; }
  .col2_1100 {
    width: 8.33333%; }
  .col3_1100 {
    width: 12.5%; }
  .col4_1100 {
    width: 16.66667%; }
  .col5_1100 {
    width: 20.83333%; }
  .col6_1100 {
    width: 25%; }
  .col7_1100 {
    width: 29.16667%; }
  .col8_1100 {
    width: 33.33333%; }
  .col9_1100 {
    width: 37.5%; }
  .col10_1100 {
    width: 41.66667%; }
  .col11_1100 {
    width: 45.83333%; }
  .col12_1100 {
    width: 50%; }
  .col13_1100 {
    width: 54.16667%; }
  .col14_1100 {
    width: 58.33333%; }
  .col15_1100 {
    width: 62.5%; }
  .col16_1100 {
    width: 66.66667%; }
  .col17_1100 {
    width: 70.83333%; }
  .col18_1100 {
    width: 75%; }
  .col19_1100 {
    width: 79.16667%; }
  .col20_1100 {
    width: 83.33333%; }
  .col21_1100 {
    width: 87.5%; }
  .col22_1100 {
    width: 91.66667%; }
  .col23_1100 {
    width: 95.83333%; }
  .col24_1100 {
    width: 100%; }
  .col1_1100, .col2_1100, .col3_1100, .col4_1100, .col5_1100, .col6_1100,
  .col7_1100, .col8_1100, .col9_1100, .col10_1100, .col11_1100, .col12_1100,
  .col13_1100, .col14_1100, .col15_1100, .col16_1100, .col17_1100, .col18_1100,
  .col19_1100, .col20_1100, .col21_1100, .col22_1100, .col23_1100, .col24_1100 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 0; } }

@media screen and (max-width: 1024px) {
  .col1_1024 {
    width: 4.16667%; }
  .col2_1024 {
    width: 8.33333%; }
  .col3_1024 {
    width: 12.5%; }
  .col4_1024 {
    width: 16.66667%; }
  .col5_1024 {
    width: 20.83333%; }
  .col6_1024 {
    width: 25%; }
  .col7_1024 {
    width: 29.16667%; }
  .col8_1024 {
    width: 33.33333%; }
  .col9_1024 {
    width: 37.5%; }
  .col10_1024 {
    width: 41.66667%; }
  .col11_1024 {
    width: 45.83333%; }
  .col12_1024 {
    width: 50%; }
  .col13_1024 {
    width: 54.16667%; }
  .col14_1024 {
    width: 58.33333%; }
  .col15_1024 {
    width: 62.5%; }
  .col16_1024 {
    width: 66.66667%; }
  .col17_1024 {
    width: 70.83333%; }
  .col18_1024 {
    width: 75%; }
  .col19_1024 {
    width: 79.16667%; }
  .col20_1024 {
    width: 83.33333%; }
  .col21_1024 {
    width: 87.5%; }
  .col22_1024 {
    width: 91.66667%; }
  .col23_1024 {
    width: 95.83333%; }
  .col24_1024 {
    width: 100%; }
  .col1_1024, .col2_1024, .col3_1024, .col4_1024, .col5_1024, .col6_1024,
  .col7_1024, .col8_1024, .col9_1024, .col10_1024, .col11_1024, .col12_1024,
  .col13_1024, .col14_1024, .col15_1024, .col16_1024, .col17_1024, .col18_1024,
  .col19_1024, .col20_1024, .col21_1024, .col22_1024, .col23_1024, .col24_1024 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 0; } }

@media screen and (max-width: 1000px) {
  .col1_1000 {
    width: 4.16667%; }
  .col2_1000 {
    width: 8.33333%; }
  .col3_1000 {
    width: 12.5%; }
  .col4_1000 {
    width: 16.66667%; }
  .col5_1000 {
    width: 20.83333%; }
  .col6_1000 {
    width: 25%; }
  .col7_1000 {
    width: 29.16667%; }
  .col8_1000 {
    width: 33.33333%; }
  .col9_1000 {
    width: 37.5%; }
  .col10_1000 {
    width: 41.66667%; }
  .col11_1000 {
    width: 45.83333%; }
  .col12_1000 {
    width: 50%; }
  .col13_1000 {
    width: 54.16667%; }
  .col14_1000 {
    width: 58.33333%; }
  .col15_1000 {
    width: 62.5%; }
  .col16_1000 {
    width: 66.66667%; }
  .col17_1000 {
    width: 70.83333%; }
  .col18_1000 {
    width: 75%; }
  .col19_1000 {
    width: 79.16667%; }
  .col20_1000 {
    width: 83.33333%; }
  .col21_1000 {
    width: 87.5%; }
  .col22_1000 {
    width: 91.66667%; }
  .col23_1000 {
    width: 95.83333%; }
  .col24_1000 {
    width: 100%; }
  .col1_1000, .col2_1000, .col3_1000, .col4_1000, .col5_1000, .col6_1000,
  .col7_1000, .col8_1000, .col9_1000, .col10_1000, .col11_1000, .col12_1000,
  .col13_1000, .col14_1000, .col15_1000, .col16_1000, .col17_1000, .col18_1000,
  .col19_1000, .col20_1000, .col21_1000, .col22_1000, .col23_1000, .col24_1000 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 0; } }

@media screen and (max-width: 900px) {
  .col1_900 {
    width: 4.16667%; }
  .col2_900 {
    width: 8.33333%; }
  .col3_900 {
    width: 12.5%; }
  .col4_900 {
    width: 16.66667%; }
  .col5_900 {
    width: 20.83333%; }
  .col6_900 {
    width: 25%; }
  .col7_900 {
    width: 29.16667%; }
  .col8_900 {
    width: 33.33333%; }
  .col9_900 {
    width: 37.5%; }
  .col10_900 {
    width: 41.66667%; }
  .col11_900 {
    width: 45.83333%; }
  .col12_900 {
    width: 50%; }
  .col13_900 {
    width: 54.16667%; }
  .col14_900 {
    width: 58.33333%; }
  .col15_900 {
    width: 62.5%; }
  .col16_900 {
    width: 66.66667%; }
  .col17_900 {
    width: 70.83333%; }
  .col18_900 {
    width: 75%; }
  .col19_900 {
    width: 79.16667%; }
  .col20_900 {
    width: 83.33333%; }
  .col21_900 {
    width: 87.5%; }
  .col22_900 {
    width: 91.66667%; }
  .col23_900 {
    width: 95.83333%; }
  .col24_900 {
    width: 100%; }
  .col1_900, .col2_900, .col3_900, .col4_900, .col5_900, .col6_900,
  .col7_900, .col8_900, .col9_900, .col10_900, .col11_900, .col12_900,
  .col13_900, .col14_900, .col15_900, .col16_900, .col17_900, .col18_900,
  .col19_900, .col20_900, .col21_900, .col22_900, .col23_900, .col24_900 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 0; } }

@media screen and (max-width: 800px) {
  .col1_800 {
    width: 4.16667%; }
  .col2_800 {
    width: 8.33333%; }
  .col3_800 {
    width: 12.5%; }
  .col4_800 {
    width: 16.66667%; }
  .col5_800 {
    width: 20.83333%; }
  .col6_800 {
    width: 25%; }
  .col7_800 {
    width: 29.16667%; }
  .col8_800 {
    width: 33.33333%; }
  .col9_800 {
    width: 37.5%; }
  .col10_800 {
    width: 41.66667%; }
  .col11_800 {
    width: 45.83333%; }
  .col12_800 {
    width: 50%; }
  .col13_800 {
    width: 54.16667%; }
  .col14_800 {
    width: 58.33333%; }
  .col15_800 {
    width: 62.5%; }
  .col16_800 {
    width: 66.66667%; }
  .col17_800 {
    width: 70.83333%; }
  .col18_800 {
    width: 75%; }
  .col19_800 {
    width: 79.16667%; }
  .col20_800 {
    width: 83.33333%; }
  .col21_800 {
    width: 87.5%; }
  .col22_800 {
    width: 91.66667%; }
  .col23_800 {
    width: 95.83333%; }
  .col24_800 {
    width: 100%; }
  .col1_800, .col2_800, .col3_800, .col4_800, .col5_800, .col6_800,
  .col7_800, .col8_800, .col9_800, .col10_800, .col11_800, .col12_800,
  .col13_800, .col14_800, .col15_800, .col16_800, .col17_800, .col18_800,
  .col19_800, .col20_800, .col21_800, .col22_800, .col23_800, .col24_800 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 0; } }

@media screen and (max-width: 768px) {
  .col1_768 {
    width: 4.16667%; }
  .col2_768 {
    width: 8.33333%; }
  .col3_768 {
    width: 12.5%; }
  .col4_768 {
    width: 16.66667%; }
  .col5_768 {
    width: 20.83333%; }
  .col6_768 {
    width: 25%; }
  .col7_768 {
    width: 29.16667%; }
  .col8_768 {
    width: 33.33333%; }
  .col9_768 {
    width: 37.5%; }
  .col10_768 {
    width: 41.66667%; }
  .col11_768 {
    width: 45.83333%; }
  .col12_768 {
    width: 50%; }
  .col13_768 {
    width: 54.16667%; }
  .col14_768 {
    width: 58.33333%; }
  .col15_768 {
    width: 62.5%; }
  .col16_768 {
    width: 66.66667%; }
  .col17_768 {
    width: 70.83333%; }
  .col18_768 {
    width: 75%; }
  .col19_768 {
    width: 79.16667%; }
  .col20_768 {
    width: 83.33333%; }
  .col21_768 {
    width: 87.5%; }
  .col22_768 {
    width: 91.66667%; }
  .col23_768 {
    width: 95.83333%; }
  .col24_768 {
    width: 100%; }
  .col1_768, .col2_768, .col3_768, .col4_768, .col5_768, .col6_768,
  .col7_768, .col8_768, .col9_768, .col10_768, .col11_768, .col12_768,
  .col13_768, .col14_768, .col15_768, .col16_768, .col17_768, .col18_768,
  .col19_768, .col20_768, .col21_768, .col22_768, .col23_768, .col24_768 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 0; } }

@media screen and (max-width: 700px) {
  .col1_700 {
    width: 4.16667%; }
  .col2_700 {
    width: 8.33333%; }
  .col3_700 {
    width: 12.5%; }
  .col4_700 {
    width: 16.66667%; }
  .col5_700 {
    width: 20.83333%; }
  .col6_700 {
    width: 25%; }
  .col7_700 {
    width: 29.16667%; }
  .col8_700 {
    width: 33.33333%; }
  .col9_700 {
    width: 37.5%; }
  .col10_700 {
    width: 41.66667%; }
  .col11_700 {
    width: 45.83333%; }
  .col12_700 {
    width: 50%; }
  .col13_700 {
    width: 54.16667%; }
  .col14_700 {
    width: 58.33333%; }
  .col15_700 {
    width: 62.5%; }
  .col16_700 {
    width: 66.66667%; }
  .col17_700 {
    width: 70.83333%; }
  .col18_700 {
    width: 75%; }
  .col19_700 {
    width: 79.16667%; }
  .col20_700 {
    width: 83.33333%; }
  .col21_700 {
    width: 87.5%; }
  .col22_700 {
    width: 91.66667%; }
  .col23_700 {
    width: 95.83333%; }
  .col24_700 {
    width: 100%; }
  .col1_700, .col2_700, .col3_700, .col4_700, .col5_700, .col6_700,
  .col7_700, .col8_700, .col9_700, .col10_700, .col11_700, .col12_700,
  .col13_700, .col14_700, .col15_700, .col16_700, .col17_700, .col18_700,
  .col19_700, .col20_700, .col21_700, .col22_700, .col23_700, .col24_700 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 0; } }

@media screen and (max-width: 600px) {
  .col1_600 {
    width: 4.16667%; }
  .col2_600 {
    width: 8.33333%; }
  .col3_600 {
    width: 12.5%; }
  .col4_600 {
    width: 16.66667%; }
  .col5_600 {
    width: 20.83333%; }
  .col6_600 {
    width: 25%; }
  .col7_600 {
    width: 29.16667%; }
  .col8_600 {
    width: 33.33333%; }
  .col9_600 {
    width: 37.5%; }
  .col10_600 {
    width: 41.66667%; }
  .col11_600 {
    width: 45.83333%; }
  .col12_600 {
    width: 50%; }
  .col13_600 {
    width: 54.16667%; }
  .col14_600 {
    width: 58.33333%; }
  .col15_600 {
    width: 62.5%; }
  .col16_600 {
    width: 66.66667%; }
  .col17_600 {
    width: 70.83333%; }
  .col18_600 {
    width: 75%; }
  .col19_600 {
    width: 79.16667%; }
  .col20_600 {
    width: 83.33333%; }
  .col21_600 {
    width: 87.5%; }
  .col22_600 {
    width: 91.66667%; }
  .col23_600 {
    width: 95.83333%; }
  .col24_600 {
    width: 100%; }
  .col1_600, .col2_600, .col3_600, .col4_600, .col5_600, .col6_600,
  .col7_600, .col8_600, .col9_600, .col10_600, .col11_600, .col12_600,
  .col13_600, .col14_600, .col15_600, .col16_600, .col17_600, .col18_600,
  .col19_600, .col20_600, .col21_600, .col22_600, .col23_600, .col24_600 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 0; } }

@media screen and (max-width: 500px) {
  .col1_500 {
    width: 4.16667%; }
  .col2_500 {
    width: 8.33333%; }
  .col3_500 {
    width: 12.5%; }
  .col4_500 {
    width: 16.66667%; }
  .col5_500 {
    width: 20.83333%; }
  .col6_500 {
    width: 25%; }
  .col7_500 {
    width: 29.16667%; }
  .col8_500 {
    width: 33.33333%; }
  .col9_500 {
    width: 37.5%; }
  .col10_500 {
    width: 41.66667%; }
  .col11_500 {
    width: 45.83333%; }
  .col12_500 {
    width: 50%; }
  .col13_500 {
    width: 54.16667%; }
  .col14_500 {
    width: 58.33333%; }
  .col15_500 {
    width: 62.5%; }
  .col16_500 {
    width: 66.66667%; }
  .col17_500 {
    width: 70.83333%; }
  .col18_500 {
    width: 75%; }
  .col19_500 {
    width: 79.16667%; }
  .col20_500 {
    width: 83.33333%; }
  .col21_500 {
    width: 87.5%; }
  .col22_500 {
    width: 91.66667%; }
  .col23_500 {
    width: 95.83333%; }
  .col24_500 {
    width: 100%; }
  .col1_500, .col2_500, .col3_500, .col4_500, .col5_500, .col6_500,
  .col7_500, .col8_500, .col9_500, .col10_500, .col11_500, .col12_500,
  .col13_500, .col14_500, .col15_500, .col16_500, .col17_500, .col18_500,
  .col19_500, .col20_500, .col21_500, .col22_500, .col23_500, .col24_500 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 0; } }

@media screen and (max-width: 400px) {
  .col1_400 {
    width: 4.16667%; }
  .col2_400 {
    width: 8.33333%; }
  .col3_400 {
    width: 12.5%; }
  .col4_400 {
    width: 16.66667%; }
  .col5_400 {
    width: 20.83333%; }
  .col6_400 {
    width: 25%; }
  .col7_400 {
    width: 29.16667%; }
  .col8_400 {
    width: 33.33333%; }
  .col9_400 {
    width: 37.5%; }
  .col10_400 {
    width: 41.66667%; }
  .col11_400 {
    width: 45.83333%; }
  .col12_400 {
    width: 50%; }
  .col13_400 {
    width: 54.16667%; }
  .col14_400 {
    width: 58.33333%; }
  .col15_400 {
    width: 62.5%; }
  .col16_400 {
    width: 66.66667%; }
  .col17_400 {
    width: 70.83333%; }
  .col18_400 {
    width: 75%; }
  .col19_400 {
    width: 79.16667%; }
  .col20_400 {
    width: 83.33333%; }
  .col21_400 {
    width: 87.5%; }
  .col22_400 {
    width: 91.66667%; }
  .col23_400 {
    width: 95.83333%; }
  .col24_400 {
    width: 100%; }
  .col1_400, .col2_400, .col3_400, .col4_400, .col5_400, .col6_400,
  .col7_400, .col8_400, .col9_400, .col10_400, .col11_400, .col12_400,
  .col13_400, .col14_400, .col15_400, .col16_400, .col17_400, .col18_400,
  .col19_400, .col20_400, .col21_400, .col22_400, .col23_400, .col24_400 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 0; } }

@media screen and (max-width: 300px) {
  .col1_300 {
    width: 4.16667%; }
  .col2_300 {
    width: 8.33333%; }
  .col3_300 {
    width: 12.5%; }
  .col4_300 {
    width: 16.66667%; }
  .col5_300 {
    width: 20.83333%; }
  .col6_300 {
    width: 25%; }
  .col7_300 {
    width: 29.16667%; }
  .col8_300 {
    width: 33.33333%; }
  .col9_300 {
    width: 37.5%; }
  .col10_300 {
    width: 41.66667%; }
  .col11_300 {
    width: 45.83333%; }
  .col12_300 {
    width: 50%; }
  .col13_300 {
    width: 54.16667%; }
  .col14_300 {
    width: 58.33333%; }
  .col15_300 {
    width: 62.5%; }
  .col16_300 {
    width: 66.66667%; }
  .col17_300 {
    width: 70.83333%; }
  .col18_300 {
    width: 75%; }
  .col19_300 {
    width: 79.16667%; }
  .col20_300 {
    width: 83.33333%; }
  .col21_300 {
    width: 87.5%; }
  .col22_300 {
    width: 91.66667%; }
  .col23_300 {
    width: 95.83333%; }
  .col24_300 {
    width: 100%; }
  .col1_300, .col2_300, .col3_300, .col4_300, .col5_300, .col6_300,
  .col7_300, .col8_300, .col9_300, .col10_300, .col11_300, .col12_300,
  .col13_300, .col14_300, .col15_300, .col16_300, .col17_300, .col18_300,
  .col19_300, .col20_300, .col21_300, .col22_300, .col23_300, .col24_300 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 0; } }

@font-face {
  font-family: 'Apercu-Regular';
  src: url("../fonts/apercu/Apercu-Regular.eot");
  src: url("../fonts/apercu/Apercu-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/apercu/Apercu-Regular.svg#Apercu-Regular") format("svg"), url("../fonts/apercu/Apercu-Regular.ttf") format("truetype"), url("../fonts/apercu/Apercu-Regular.woff") format("woff"), url("../fonts/apercu/Apercu-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Apercu-Medium';
  src: url("../fonts/apercu/Apercu-Medium.eot");
  src: url("../fonts/apercu/Apercu-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/apercu/Apercu-Medium.svg#Apercu-Medium") format("svg"), url("../fonts/apercu/Apercu-Medium.ttf") format("truetype"), url("../fonts/apercu/Apercu-Medium.woff") format("woff"), url("../fonts/apercu/Apercu-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Apercu-Bold';
  src: url("../fonts/apercu/Apercu-Bold.eot");
  src: url("../fonts/apercu/Apercu-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/apercu/Apercu-Bold.svg#Apercu-Bold") format("svg"), url("../fonts/apercu/Apercu-Bold.ttf") format("truetype"), url("../fonts/apercu/Apercu-Bold.woff") format("woff"), url("../fonts/apercu/Apercu-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Apercu-Italic';
  src: url("../fonts/apercu/Apercu-Italic.eot");
  src: url("../fonts/apercu/Apercu-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/apercu/Apercu-Italic.svg#Apercu-Italic") format("svg"), url("../fonts/apercu/Apercu-Italic.ttf") format("truetype"), url("../fonts/apercu/Apercu-Italic.woff") format("woff"), url("../fonts/apercu/Apercu-Italic.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

.table {
  display: table; }

.tableCell {
  display: table-cell; }

.flex {
  display: flex;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center; }

.centerize {
  display: inline-block;
  line-height: normal;
  vertical-align: middle; }

.absolute {
  position: absolute; }

.relative {
  position: relative; }

.fixed {
  position: fixed; }

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 50px; }

.floatLeft {
  float: left; }

.floatRight {
  float: right; }

.textLeft {
  text-align: left; }

.textCenter {
  text-align: center; }

.textRight {
  text-align: right; }

.alignTop {
  vertical-align: top; }

.alignMiddle {
  vertical-align: middle; }

.alignBottom {
  vertical-align: bottom; }

.wrapper1600 {
  max-width: 1600px;
  margin: 0 auto; }
  @media screen and (max-width: 1600px) {
    .wrapper1600 {
      margin: 0; } }

.wrapper1500 {
  max-width: 1500px;
  margin: 0 auto; }
  @media screen and (max-width: 1500px) {
    .wrapper1500 {
      margin: 0; } }

.wrapper1400 {
  max-width: 1400px;
  margin: 0 auto; }
  @media screen and (max-width: 1400px) {
    .wrapper1400 {
      margin: 0; } }

.wrapper1300 {
  max-width: 1300px;
  margin: 0 auto; }
  @media screen and (max-width: 1300px) {
    .wrapper1300 {
      margin: 0; } }

.wrapper1200 {
  max-width: 1200px;
  margin: 0 auto; }
  @media screen and (max-width: 1200px) {
    .wrapper1200 {
      margin: 0; } }

.wrapper1100 {
  max-width: 1100px;
  margin: 0 auto; }
  @media screen and (max-width: 1100px) {
    .wrapper1100 {
      margin: 0; } }

.wrapper1024 {
  max-width: 1024px;
  margin: 0 auto; }
  @media screen and (max-width: 1024px) {
    .wrapper1024 {
      margin: 0; } }

.wrapper700 {
  max-width: 700px;
  margin: 0 auto; }
  @media screen and (max-width: 700px) {
    .wrapper700 {
      margin: 0; } }

.ylPadding {
  padding-top: 160px;
  padding-bottom: 160px; }
  @media screen and (max-width: 1600px) {
    .ylPadding {
      padding-top: 120px;
      padding-bottom: 120px; } }
  @media screen and (max-width: 1400px) {
    .ylPadding {
      padding-top: 100px;
      padding-bottom: 100px; } }
  @media screen and (max-width: 1024px) {
    .ylPadding {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media screen and (max-width: 768px) {
    .ylPadding {
      padding-top: 60px;
      padding-bottom: 60px; } }
  @media screen and (max-width: 500px) {
    .ylPadding {
      padding-top: 50px;
      padding-bottom: 50px; } }

.ylTopPadding {
  padding-top: 160px; }
  @media screen and (max-width: 1600px) {
    .ylTopPadding {
      padding-top: 120px; } }
  @media screen and (max-width: 1400px) {
    .ylTopPadding {
      padding-top: 100px; } }
  @media screen and (max-width: 1024px) {
    .ylTopPadding {
      padding-top: 80px; } }
  @media screen and (max-width: 768px) {
    .ylTopPadding {
      padding-top: 60px; } }
  @media screen and (max-width: 500px) {
    .ylTopPadding {
      padding-top: 50px; } }

.ylBottomPadding {
  padding-bottom: 160px; }
  @media screen and (max-width: 1600px) {
    .ylBottomPadding {
      padding-bottom: 120px; } }
  @media screen and (max-width: 1400px) {
    .ylBottomPadding {
      padding-bottom: 100px; } }
  @media screen and (max-width: 1024px) {
    .ylBottomPadding {
      padding-bottom: 80px; } }
  @media screen and (max-width: 768px) {
    .ylBottomPadding {
      padding-bottom: 60px; } }
  @media screen and (max-width: 500px) {
    .ylBottomPadding {
      padding-bottom: 50px; } }

.yPadding {
  padding-top: 120px;
  padding-bottom: 120px; }
  @media screen and (max-width: 1600px) {
    .yPadding {
      padding-top: 100px;
      padding-bottom: 100px; } }
  @media screen and (max-width: 1400px) {
    .yPadding {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media screen and (max-width: 1024px) {
    .yPadding {
      padding-top: 70px;
      padding-bottom: 70px; } }
  @media screen and (max-width: 768px) {
    .yPadding {
      padding-top: 60px;
      padding-bottom: 60px; } }
  @media screen and (max-width: 500px) {
    .yPadding {
      padding-top: 50px;
      padding-bottom: 50px; } }

.yTopPadding {
  padding-top: 120px; }
  @media screen and (max-width: 1600px) {
    .yTopPadding {
      padding-top: 100px; } }
  @media screen and (max-width: 1400px) {
    .yTopPadding {
      padding-top: 80px; } }
  @media screen and (max-width: 1024px) {
    .yTopPadding {
      padding-top: 70px; } }
  @media screen and (max-width: 768px) {
    .yTopPadding {
      padding-top: 60px; } }
  @media screen and (max-width: 500px) {
    .yTopPadding {
      padding-top: 50px; } }

.yBottomPadding {
  padding-bottom: 120px; }
  @media screen and (max-width: 1600px) {
    .yBottomPadding {
      padding-bottom: 100px; } }
  @media screen and (max-width: 1400px) {
    .yBottomPadding {
      padding-bottom: 80px; } }
  @media screen and (max-width: 1024px) {
    .yBottomPadding {
      padding-bottom: 70px; } }
  @media screen and (max-width: 768px) {
    .yBottomPadding {
      padding-bottom: 60px; } }
  @media screen and (max-width: 500px) {
    .yBottomPadding {
      padding-bottom: 50px; } }

.yBottomPaddingXS {
  padding-bottom: 60px; }
  @media screen and (max-width: 1600px) {
    .yBottomPaddingXS {
      padding-bottom: 50px; } }
  @media screen and (max-width: 1400px) {
    .yBottomPaddingXS {
      padding-bottom: 40px; } }
  @media screen and (max-width: 1024px) {
    .yBottomPaddingXS {
      padding-bottom: 35px; } }
  @media screen and (max-width: 768px) {
    .yBottomPaddingXS {
      padding-bottom: 30px; } }
  @media screen and (max-width: 500px) {
    .yBottomPaddingXS {
      padding-bottom: 25px; } }

.ysPadding {
  padding-top: 80px;
  padding-bottom: 80px; }
  @media screen and (max-width: 1200px) {
    .ysPadding {
      padding-top: 60px;
      padding-bottom: 60px; } }
  @media screen and (max-width: 500px) {
    .ysPadding {
      padding-top: 50px;
      padding-bottom: 50px; } }

.ysTopPadding {
  padding-top: 80px; }
  @media screen and (max-width: 1200px) {
    .ysTopPadding {
      padding-top: 60px; } }
  @media screen and (max-width: 500px) {
    .ysTopPadding {
      padding-top: 50px; } }

.ysBottomPadding {
  padding-bottom: 80px; }
  @media screen and (max-width: 1200px) {
    .ysBottomPadding {
      padding-bottom: 60px; } }
  @media screen and (max-width: 500px) {
    .ysBottomPadding {
      padding-bottom: 50px; } }

.xPadding {
  padding-right: 160px;
  padding-left: 160px; }
  @media screen and (max-width: 1600px) {
    .xPadding {
      padding-right: 130px;
      padding-left: 130px; } }
  @media screen and (max-width: 1400px) {
    .xPadding {
      padding-right: 100px;
      padding-left: 100px; } }
  @media screen and (max-width: 1200px) {
    .xPadding {
      padding-right: 80px;
      padding-left: 80px; } }
  @media screen and (max-width: 1024px) {
    .xPadding {
      padding-right: 30px;
      padding-left: 30px; } }
  @media screen and (max-width: 768px) {
    .xPadding {
      padding-right: 20px;
      padding-left: 20px; } }

.xRightPadding {
  padding-right: 160px; }
  @media screen and (max-width: 1600px) {
    .xRightPadding {
      padding-right: 130px; } }
  @media screen and (max-width: 1400px) {
    .xRightPadding {
      padding-right: 100px; } }
  @media screen and (max-width: 1200px) {
    .xRightPadding {
      padding-right: 80px; } }
  @media screen and (max-width: 1024px) {
    .xRightPadding {
      padding-right: 30px; } }
  @media screen and (max-width: 768px) {
    .xRightPadding {
      padding-right: 20px; } }

.xLeftPadding {
  padding-left: 160px; }
  @media screen and (max-width: 1600px) {
    .xLeftPadding {
      padding-left: 130px; } }
  @media screen and (max-width: 1400px) {
    .xLeftPadding {
      padding-left: 100px; } }
  @media screen and (max-width: 1200px) {
    .xLeftPadding {
      padding-left: 80px; } }
  @media screen and (max-width: 1024px) {
    .xLeftPadding {
      padding-left: 30px; } }
  @media screen and (max-width: 768px) {
    .xLeftPadding {
      padding-left: 20px; } }

.xsPadding {
  padding-right: 100px;
  padding-left: 100px; }
  @media screen and (max-width: 1600px) {
    .xsPadding {
      padding-right: 75px;
      padding-left: 75px; } }
  @media screen and (max-width: 1400px) {
    .xsPadding {
      padding-right: 50px;
      padding-left: 50px; } }
  @media screen and (max-width: 1024px) {
    .xsPadding {
      padding-right: 30px;
      padding-left: 30px; } }
  @media screen and (max-width: 768px) {
    .xsPadding {
      padding-right: 20px;
      padding-left: 20px; } }

.xsRightPadding {
  padding-right: 100px; }
  @media screen and (max-width: 1600px) {
    .xsRightPadding {
      padding-right: 75px; } }
  @media screen and (max-width: 1400px) {
    .xsRightPadding {
      padding-right: 50px; } }
  @media screen and (max-width: 1024px) {
    .xsRightPadding {
      padding-right: 30px; } }
  @media screen and (max-width: 768px) {
    .xsRightPadding {
      padding-right: 20px; } }

.xsLeftPadding {
  padding-left: 100px; }
  @media screen and (max-width: 1600px) {
    .xsLeftPadding {
      padding-left: 75px; } }
  @media screen and (max-width: 1400px) {
    .xsLeftPadding {
      padding-left: 50px; } }
  @media screen and (max-width: 1024px) {
    .xsLeftPadding {
      padding-left: 30px; } }
  @media screen and (max-width: 768px) {
    .xsLeftPadding {
      padding-left: 20px; } }

.noTopPadding {
  padding-top: 0; }

.noRightPadding {
  padding-right: 0; }

.noBottomPadding {
  padding-bottom: 0; }

.noLeftPadding {
  padding-left: 0; }

.noXPadding {
  padding-right: 0;
  padding-left: 0; }

.noYPadding {
  padding-top: 0;
  padding-bottom: 0; }

.noPadding {
  padding: 0; }

.noTopMargin {
  margin-top: 0; }

.noRightMargin {
  margin-right: 0; }

.noBottomMargin {
  margin-bottom: 0; }

.noLeftMargin {
  margin-left: 0; }

.noXMargin {
  margin-right: 0;
  margin-left: 0; }

.noYMargin {
  margin-top: 0;
  margin-bottom: 0; }

.nowrap {
  white-space: nowrap; }

.clearfloat {
  display: block;
  clear: both; }

.clearfix::after {
  display: table;
  content: '';
  clear: both; }

.noselect {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.hide {
  display: none; }

@media screen and (max-width: 1800px) {
  .hide_1800 {
    display: none; } }

@media screen and (max-width: 1700px) {
  .hide_1700 {
    display: none; } }

@media screen and (max-width: 1600px) {
  .hide_1600 {
    display: none; } }

@media screen and (max-width: 1500px) {
  .hide_1500 {
    display: none; } }

@media screen and (max-width: 1400px) {
  .hide_1400 {
    display: none; } }

@media screen and (max-width: 1300px) {
  .hide_1300 {
    display: none; } }

@media screen and (max-width: 1200px) {
  .hide_1200 {
    display: none; } }

@media screen and (max-width: 1100px) {
  .hide_1100 {
    display: none; } }

@media screen and (max-width: 1024px) {
  .hide_1024 {
    display: none; } }

@media screen and (max-width: 1000px) {
  .hide_1000 {
    display: none; } }

@media screen and (max-width: 900px) {
  .hide_900 {
    display: none; } }

@media screen and (max-width: 800px) {
  .hide_800 {
    display: none; } }

@media screen and (max-width: 768px) {
  .hide_768 {
    display: none; } }

@media screen and (max-width: 700px) {
  .hide_700 {
    display: none; } }

@media screen and (max-width: 600px) {
  .hide_600 {
    display: none; } }

@media screen and (max-width: 500px) {
  .hide_500 {
    display: none; } }

@media screen and (max-width: 400px) {
  .hide_400 {
    display: none; } }

@media screen and (max-width: 300px) {
  .hide_300 {
    display: none; } }

.show {
  display: block; }

.show_1800 {
  display: none; }
  @media screen and (max-width: 1800px) {
    .show_1800 {
      display: block; } }

.show_1700 {
  display: none; }
  @media screen and (max-width: 1700px) {
    .show_1700 {
      display: block; } }

.show_1600 {
  display: none; }
  @media screen and (max-width: 1600px) {
    .show_1600 {
      display: block; } }

.show_1500 {
  display: none; }
  @media screen and (max-width: 1500px) {
    .show_1500 {
      display: block; } }

.show_1400 {
  display: none; }
  @media screen and (max-width: 1400px) {
    .show_1400 {
      display: block; } }

.show_1300 {
  display: none; }
  @media screen and (max-width: 1300px) {
    .show_1300 {
      display: block; } }

.show_1200 {
  display: none; }
  @media screen and (max-width: 1200px) {
    .show_1200 {
      display: block; } }

.show_1100 {
  display: none; }
  @media screen and (max-width: 1100px) {
    .show_1100 {
      display: block; } }

.show_1024 {
  display: none; }
  @media screen and (max-width: 1024px) {
    .show_1024 {
      display: block; } }

.show_1000 {
  display: none; }
  @media screen and (max-width: 1000px) {
    .show_1000 {
      display: block; } }

.show_900 {
  display: none; }
  @media screen and (max-width: 900px) {
    .show_900 {
      display: block; } }

.show_800 {
  display: none; }
  @media screen and (max-width: 800px) {
    .show_800 {
      display: block; } }

.show_768 {
  display: none; }
  @media screen and (max-width: 768px) {
    .show_768 {
      display: block; } }

.show_700 {
  display: none; }
  @media screen and (max-width: 700px) {
    .show_700 {
      display: block; } }

.show_600 {
  display: none; }
  @media screen and (max-width: 600px) {
    .show_600 {
      display: block; } }

.show_500 {
  display: none; }
  @media screen and (max-width: 500px) {
    .show_500 {
      display: block; } }

.show_400 {
  display: none; }
  @media screen and (max-width: 400px) {
    .show_400 {
      display: block; } }

.show_300 {
  display: none; }
  @media screen and (max-width: 300px) {
    .show_300 {
      display: block; } }

.mb {
  margin-bottom: 15px; }

html {
  font: 12px/1 "Apercu-Regular", Arial, sans-serif;
  background: #ececec; }

h1 {
  margin: 0 0 60px 0;
  font: 66px/1.1 "Apercu-Bold";
  color: #252b44; }
  @media screen and (max-width: 1400px) {
    h1 {
      font-size: 60px;
      margin-bottom: 50px; } }
  @media screen and (max-width: 1200px) {
    h1 {
      font-size: 54px; } }
  @media screen and (max-width: 1024px) {
    h1 {
      font-size: 48px;
      margin-bottom: 40px; } }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 42px;
      margin-bottom: 35px; } }
  @media screen and (max-width: 600px) {
    h1 {
      font-size: 36px;
      margin-bottom: 30px; } }
  @media screen and (max-width: 500px) {
    h1 {
      font-size: 32px; } }
  @media screen and (max-width: 400px) {
    h1 {
      font-size: 30px; } }

h2 {
  margin: 0 0 45px 0;
  font: 45px/1.1 "Apercu-Bold";
  color: #252b44; }
  @media screen and (max-width: 1600px) {
    h2 {
      margin-bottom: 35px; } }
  @media screen and (max-width: 1400px) {
    h2 {
      font-size: 40px; } }
  @media screen and (max-width: 1200px) {
    h2 {
      font-size: 38px;
      margin-bottom: 30px; } }
  @media screen and (max-width: 1024px) {
    h2 {
      font-size: 34px;
      margin-bottom: 20px; } }
  @media screen and (max-width: 768px) {
    h2 {
      font-size: 28px; } }
  @media screen and (max-width: 600px) {
    h2 {
      font-size: 24px; } }
  @media screen and (max-width: 500px) {
    h2 {
      font-size: 23px; } }
  @media screen and (max-width: 400px) {
    h2 {
      font-size: 22px; } }

h3 {
  margin: 0;
  font: 35px/1.15 "Apercu-Bold";
  color: #252b44; }
  @media screen and (max-width: 1600px) {
    h3 {
      font-size: 30px; } }
  @media screen and (max-width: 1200px) {
    h3 {
      font-size: 26px; } }
  @media screen and (max-width: 1024px) {
    h3 {
      font-size: 22px; } }
  @media screen and (max-width: 600px) {
    h3 {
      font-size: 20px; } }

h4 {
  margin: 0;
  font: 30px/1.5 "Apercu-Regular";
  color: #252b44; }
  @media screen and (max-width: 1400px) {
    h4 {
      font-size: 26px; } }
  @media screen and (max-width: 1200px) {
    h4 {
      font-size: 24px; } }
  @media screen and (max-width: 1024px) {
    h4 {
      font-size: 22px; } }
  @media screen and (max-width: 768px) {
    h4 {
      font-size: 20px; } }
  @media screen and (max-width: 500px) {
    h4 {
      font-size: 19px; } }

h5 {
  margin: 0 0 20px 0;
  font: 22px/1.2 "Apercu-Regular";
  color: #526786; }
  @media screen and (max-width: 1200px) {
    h5 {
      font-size: 20px; } }
  @media screen and (max-width: 1024px) {
    h5 {
      margin-bottom: 15px; } }
  @media screen and (max-width: 768px) {
    h5 {
      font-size: 18px;
      margin-bottom: 10px; } }

h6 {
  margin: 0 0 10px 0;
  font: 12px/15px "Apercu-Medium";
  color: #252b44;
  letter-spacing: 1px;
  text-transform: uppercase; }

p {
  margin: 0 0 20px 0;
  font: 17px/1.6 "Apercu-Regular";
  color: #252b44; }
  @media screen and (max-width: 768px) {
    p {
      font-size: 16px;
      margin-bottom: 15px; } }
  @media screen and (max-width: 600px) {
    p {
      font-size: 15px; } }
  p.date {
    font: 14px/20px "Apercu-Medium";
    color: #a5a5a5; }
    @media screen and (max-width: 600px) {
      p.date {
        margin-bottom: 10px; } }
    p.date span {
      white-space: nowrap;
      color: #252b44; }

.maxWidth {
  max-width: 1200px; }

.bg-gray {
  background: #ececec; }

.xMargin {
  margin-bottom: 50px; }
  @media screen and (max-width: 1400px) {
    .xMargin {
      margin-bottom: 40px; } }
  @media screen and (max-width: 600px) {
    .xMargin {
      margin-bottom: 30px; } }

.icon {
  width: 1.3em;
  height: 1.3em;
  vertical-align: -0.3em;
  fill: #252b44;
  stroke: none;
  transition: all 300ms; }
  .icon.icon-stroke {
    fill: none;
    stroke: #252b44;
    stroke-width: 5px; }

.btn {
  position: relative;
  display: inline-block;
  padding: 22px 35px;
  font: 15px/20px "Apercu-Medium";
  color: #fff;
  text-align: center;
  background: #526786;
  z-index: 1;
  transition: color 350ms;
  cursor: pointer; }
  @media screen and (max-width: 1024px) {
    .btn {
      padding: 18px 32px; } }
  @media screen and (max-width: 768px) {
    .btn {
      padding: 16px 25px; } }
  @media screen and (max-width: 600px) {
    .btn {
      font-size: 14px; } }
  @media screen and (max-width: 500px) {
    .btn {
      padding: 14px 22px; } }
  .btn::after {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    content: '';
    background: #252b44;
    z-index: -1;
    transition: width 700ms cubic-bezier(0.19, 1, 0.22, 1); }
  .btn:hover {
    color: #fff; }
    .btn:hover::after {
      width: 100%; }
  .btn.btnWhite {
    color: #252b44;
    background: #fff; }
    .btn.btnWhite:hover {
      color: #fff; }

.shareBtn {
  position: fixed;
  height: 40px;
  width: 40px;
  background-color: white;
  right: 0;
  bottom: 10%;
  display: block;
  z-index: 49;
  cursor: pointer;
  box-shadow: 0 0px 10px -5px rgba(0, 0, 0, 0.6);
  transition: background-color cubic-bezier(0.23, 1, 0.32, 1) 0.3s; }
  .shareBtn svg {
    height: 16px;
    width: 16px;
    fill: #252b44;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: fill cubic-bezier(0.23, 1, 0.32, 1) 0.3s; }
  .shareBtn:hover {
    background-color: #252b44; }
    .shareBtn:hover svg {
      fill: white; }

.ocListCt .shareBtn {
  position: absolute;
  height: 60px;
  width: 60px;
  display: block;
  border-radius: 50%;
  top: 40px;
  right: 45px;
  box-shadow: none;
  background-color: #ececec; }
  @media screen and (max-width: 1600px) {
    .ocListCt .shareBtn {
      height: 40px;
      width: 40px;
      right: 25px; }
      .ocListCt .shareBtn svg {
        height: 14px;
        width: 14px; } }
  @media screen and (max-width: 600px) {
    .ocListCt .shareBtn {
      position: relative;
      padding: 10px;
      top: auto;
      right: auto; } }
  .ocListCt .shareBtn:hover {
    background-color: #252b44; }

.link {
  position: relative;
  display: inline-block;
  height: 20px;
  padding-right: 50px;
  font: 15px/20px "Apercu-Medium";
  color: #252b44;
  transition: color 300ms; }
  @media screen and (max-width: 600px) {
    .link {
      padding-right: 40px;
      font-size: 14px; } }
  .link .icons-box {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    height: 20px;
    font-size: 25px;
    overflow: hidden; }
    @media screen and (max-width: 600px) {
      .link .icons-box {
        font-size: 20px; } }
    .link .icons-box span {
      display: block;
      width: 100%;
      height: 100%;
      transform: translateY(-5px);
      -webkit-transform: translateY(-5px);
      transition: transform 800ms cubic-bezier(0.23, 1, 0.32, 1);
      -webkit-transition: -webkit-transform 800ms cubic-bezier(0.23, 1, 0.32, 1); }
      @media screen and (max-width: 600px) {
        .link .icons-box span {
          transform: translateY(-3px);
          -webkit-transform: translateY(-3px); } }
      .link .icons-box span .icon {
        vertical-align: middle;
        stroke: #526786; }
    .link .icons-box .icon-left {
      position: absolute;
      left: -200%;
      top: 0;
      bottom: 0; }
  .link:hover {
    color: #526786; }
    .link:hover .icons-box span {
      transform: translate(200%, -5px);
      -webkit-transform: -webkit-translateX(200%, -5px); }
      @media screen and (max-width: 600px) {
        .link:hover .icons-box span {
          transform: translate(200%, -3px);
          -webkit-transform: -webkit-translateX(200%, -3px); } }

.arrowBtn {
  display: block;
  width: 70px;
  height: 70px;
  font-size: 25px;
  line-height: 70px;
  text-align: center;
  background: #fff !important;
  overflow: hidden; }
  @media screen and (max-width: 1400px) {
    .arrowBtn {
      width: 60px;
      height: 60px;
      line-height: 60px;
      font-size: 22px; } }
  @media screen and (max-width: 1200px) {
    .arrowBtn {
      width: 54px;
      height: 54px;
      line-height: 54px;
      font-size: 20px; } }
  .arrowBtn .icons-box {
    position: relative;
    display: block;
    height: 100%;
    width: 100%; }
    .arrowBtn .icons-box span {
      display: block;
      width: 100%;
      height: 100%;
      transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1);
      -webkit-transition: -webkit-transform 500ms cubic-bezier(0.23, 1, 0.32, 1); }
  .arrowBtn .icon-left {
    position: absolute;
    left: -100%;
    top: 0;
    bottom: 0; }
  .arrowBtn:hover .icons-box span {
    transform: translateX(100%);
    -webkit-transform: -webkit-translateX(100%); }

.focus_r {
  background-position: right center !important; }

.focus_l {
  background-position: left center !important; }

#pageTitle > div:first-child > :last-child {
  margin-bottom: 0px !important; }

#pageTitle > div:first-child h1 {
  margin-bottom: 50px;
  max-width: 1200px; }
  @media screen and (max-width: 1400px) {
    #pageTitle > div:first-child h1 {
      margin-bottom: 40px; } }
  @media screen and (max-width: 1024px) {
    #pageTitle > div:first-child h1 {
      margin-bottom: 30px; } }
  @media screen and (max-width: 600px) {
    #pageTitle > div:first-child h1 {
      margin-bottom: 25px; } }
  @media screen and (max-width: 500px) {
    #pageTitle > div:first-child h1 {
      margin-bottom: 20px; } }

#pageTitle > div:first-child .date span {
  color: #526786; }

#pageTitle > div:first-child a {
  position: relative;
  font: 15px/20px "Apercu-Medium";
  color: #252b44;
  transition: color 300ms; }
  #pageTitle > div:first-child a:hover {
    color: #526786; }
  #pageTitle > div:first-child a .icon-stroke {
    margin-right: 5px;
    width: 1.4em;
    height: 1.4em;
    stroke-width: 2px; }

#overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  visibility: hidden;
  z-index: 50;
  transition: background 400ms ease, visibility 0ms ease 400ms; }

.show-sideMenu #overlay, .show-mainNavDrop #overlay, .show-shareModal #overlay {
  visibility: visible;
  background: rgba(0, 0, 0, 0.5);
  transition: background 400ms; }

#scrollBack {
  position: absolute;
  left: 100%;
  bottom: 100%;
  display: none;
  margin: 0 0 10px -45px;
  white-space: nowrap;
  z-index: 1;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0; }
  @media screen and (max-width: 1400px) {
    #scrollBack {
      margin: 0 0 5px -40px; } }
  @media screen and (max-width: 1024px) {
    #scrollBack {
      display: none !important; } }
  #scrollBack .icons-box {
    -webkit-animation-name: floating;
            animation-name: floating;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite; }

@-webkit-keyframes floating {
  0, 0.1% {
    transform: translateX(0); }
  30% {
    transform: translateX(-20%); }
  100% {
    transform: translateX(0); } }

@keyframes floating {
  0, 0.1% {
    transform: translateX(0); }
  30% {
    transform: translateX(-20%); }
  100% {
    transform: translateX(0); } }

.centerBanner #pageTitle {
  background: #2F3755; }
  .centerBanner #pageTitle h1 {
    max-width: none !important;
    color: #fff; }

#page-error #mainNav {
  background: #2F3755; }

.submitBtn {
  text-align: center;
  clear: both; }

.submitBtnRight {
  float: right; }
  @media screen and (max-width: 1024px) {
    .submitBtnRight {
      text-align: center;
      float: none;
      clear: both; } }

.dynamic {
  /* ----- Image - No Caption ----- */
  /* ----- Image - With Caption ----- */ }
  .dynamic > :first-child {
    margin-top: 0px; }
  .dynamic > :last-child {
    margin-bottom: 0 !important; }
  .dynamic > :last-child {
    margin-bottom: 0 !important; }
  .dynamic h1 {
    margin: 60px 0 20px 0;
    font: 35px/1.15 "Apercu-Bold";
    color: #252b44;
    text-transform: none;
    letter-spacing: none; }
    @media screen and (max-width: 1600px) {
      .dynamic h1 {
        font-size: 30px; } }
    @media screen and (max-width: 1200px) {
      .dynamic h1 {
        font-size: 28px; } }
    @media screen and (max-width: 1024px) {
      .dynamic h1 {
        font-size: 25px; } }
    @media screen and (max-width: 600px) {
      .dynamic h1 {
        font-size: 23px;
        margin-top: 45px; } }
  .dynamic em {
    font-style: normal; }
  .dynamic strong {
    font-style: normal; }
  .dynamic p em {
    font-family: "Apercu-Italic"; }
  .dynamic p strong {
    font-family: "Apercu-Bold"; }
  .dynamic a {
    display: inline;
    font-family: "Apercu-Medium";
    box-shadow: inset 0px -10px 0px -1px rgba(208, 214, 221, 0.5);
    transition: box-shadow 300ms; }
    .dynamic a:hover {
      box-shadow: inset 0px -16px 0px -1px rgba(208, 214, 221, 0.5); }
  .dynamic a.btn {
    margin: 20px 0;
    display: inline-block;
    color: #fff;
    box-shadow: none;
    transition: background-color 300ms; }
    @media screen and (max-width: 600px) {
      .dynamic a.btn {
        margin: 20px 0 10px 0; } }
  .dynamic ul, .dynamic ol {
    margin: 30px 0;
    list-style-type: none;
    counter-reset: item; }
    @media screen and (max-width: 600px) {
      .dynamic ul, .dynamic ol {
        margin: 25px 0; } }
    .dynamic ul ul, .dynamic ul ol, .dynamic ol ul, .dynamic ol ol {
      margin: 20px 0; }
      @media screen and (max-width: 600px) {
        .dynamic ul ul, .dynamic ul ol, .dynamic ol ul, .dynamic ol ol {
          margin: 15px 0; } }
      .dynamic ul ul li, .dynamic ul ol li, .dynamic ol ul li, .dynamic ol ol li {
        margin-bottom: 10px;
        padding-left: 20px; }
    .dynamic ul ol, .dynamic ol ol {
      list-style-type: lower-latin; }
      .dynamic ul ol li, .dynamic ol ol li {
        margin-left: 17px;
        padding-left: 5px; }
        .dynamic ul ol li::before, .dynamic ol ol li::before {
          content: '';
          counter-increment: none; }
  .dynamic ul ul {
    list-style-type: none; }
    .dynamic ul ul li::before {
      position: absolute;
      left: 2px;
      top: 10px;
      width: 5px;
      height: 5px;
      content: '';
      background: #526786;
      border-radius: 50%;
      vertical-align: middle; }
      @media screen and (max-width: 768px) {
        .dynamic ul ul li::before {
          top: 11px; } }
  .dynamic ul > li::before {
    position: absolute;
    left: 2px;
    top: 10px;
    width: 12px;
    height: 2px;
    content: '';
    background: #526786; }
  .dynamic ol > li {
    counter-increment: item; }
    .dynamic ol > li::before {
      position: absolute;
      left: -6px;
      top: 0;
      width: 20px;
      content: counter(item) ".";
      font-family: "Apercu-Bold";
      color: #526786;
      text-align: right; }
  .dynamic li {
    position: relative;
    padding: 0 0 0 25px;
    margin: 0 0 17px 0;
    font: 17px/22px "Apercu-Regular";
    color: #252b44; }
    @media screen and (max-width: 768px) {
      .dynamic li {
        margin-bottom: 15px;
        padding-left: 20px;
        font-size: 16px; } }
    @media screen and (max-width: 600px) {
      .dynamic li {
        margin-bottom: 13px;
        font-size: 15px; } }
    .dynamic li:last-child {
      margin-bottom: 0; }
  .dynamic img {
    margin: 40px 0;
    display: block;
    max-width: 100%;
    height: auto !important; }
    @media screen and (max-width: 600px) {
      .dynamic img {
        margin: 30px 0; } }
  .dynamic blockquote {
    position: relative;
    margin: 40px 0;
    padding: 5px 0 5px 40px; }
    @media screen and (max-width: 1400px) {
      .dynamic blockquote {
        padding-left: 30px; } }
    @media screen and (max-width: 600px) {
      .dynamic blockquote {
        padding-left: 25px;
        margin: 30px 0; } }
    .dynamic blockquote p, .dynamic blockquote h1, .dynamic blockquote h2, .dynamic blockquote h3, .dynamic blockquote h4, .dynamic blockquote pre, .dynamic blockquote > * {
      margin: 0;
      font: 30px/1.5 "Apercu-Regular";
      color: #526786; }
      @media screen and (max-width: 1600px) {
        .dynamic blockquote p, .dynamic blockquote h1, .dynamic blockquote h2, .dynamic blockquote h3, .dynamic blockquote h4, .dynamic blockquote pre, .dynamic blockquote > * {
          font-size: 28px; } }
      @media screen and (max-width: 1400px) {
        .dynamic blockquote p, .dynamic blockquote h1, .dynamic blockquote h2, .dynamic blockquote h3, .dynamic blockquote h4, .dynamic blockquote pre, .dynamic blockquote > * {
          font-size: 26px;
          line-height: 1.4; } }
      @media screen and (max-width: 1200px) {
        .dynamic blockquote p, .dynamic blockquote h1, .dynamic blockquote h2, .dynamic blockquote h3, .dynamic blockquote h4, .dynamic blockquote pre, .dynamic blockquote > * {
          font-size: 24px; } }
      @media screen and (max-width: 768px) {
        .dynamic blockquote p, .dynamic blockquote h1, .dynamic blockquote h2, .dynamic blockquote h3, .dynamic blockquote h4, .dynamic blockquote pre, .dynamic blockquote > * {
          font-size: 22px; } }
      @media screen and (max-width: 600px) {
        .dynamic blockquote p, .dynamic blockquote h1, .dynamic blockquote h2, .dynamic blockquote h3, .dynamic blockquote h4, .dynamic blockquote pre, .dynamic blockquote > * {
          font-size: 20px; } }
    .dynamic blockquote::before {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 3px;
      content: '';
      background: #526786; }
  .dynamic .legend, .dynamic .annotation {
    margin: 40px 0;
    font-size: 14px;
    line-height: 18px;
    max-width: 800px; }
    @media screen and (max-width: 600px) {
      .dynamic .legend, .dynamic .annotation {
        margin: 30px 0;
        font-size: 13px; } }
  .dynamic .legend {
    margin: -25px 0 40px 0; }
    @media screen and (max-width: 600px) {
      .dynamic .legend {
        margin: -20px 0 30px 0; } }
  .dynamic table {
    width: 100%;
    margin: 40px 0;
    font: 16px/18px "Apercu-Regular";
    line-height: 20px;
    background: #fff; }
    @media screen and (max-width: 1200px) {
      .dynamic table {
        display: block;
        font-size: 15px;
        overflow-x: auto; } }
    @media screen and (max-width: 768px) {
      .dynamic table {
        font-size: 14px;
        line-height: 18px; } }
    @media screen and (max-width: 600px) {
      .dynamic table {
        font-size: 13px;
        margin: 30px auto; } }
    .dynamic table th {
      padding: 20px 15px;
      border: solid 1px #526786;
      font-family: "Apercu-Bold";
      text-align: left;
      color: #fff;
      background: #252b44; }
      @media screen and (max-width: 1200px) {
        .dynamic table th {
          padding: 15px 20px; } }
      @media screen and (max-width: 768px) {
        .dynamic table th {
          padding: 13px 20px; } }
    .dynamic table td {
      padding: 20px 15px;
      border: solid 1px #526786;
      text-align: left;
      color: #252b44; }
      @media screen and (max-width: 1200px) {
        .dynamic table td {
          padding: 15px 20px; } }
      @media screen and (max-width: 768px) {
        .dynamic table td {
          padding: 13px 20px; } }
    .dynamic table .center, .dynamic table .center td, .dynamic table .center th {
      text-align: center; }
    .dynamic table .strong, .dynamic table .strong td, .dynamic table .strong th {
      font-family: "Apercu-Bold" !important; }
    .dynamic table .title, .dynamic table .title td, .dynamic table .title th {
      font-family: "Apercu-Medium";
      text-transform: uppercase; }
  .dynamic img {
    position: relative;
    max-width: 100%; }
    .dynamic img.fr-dib {
      /* block */
      display: block;
      margin: 30px auto;
      float: none;
      vertical-align: top; }
      .dynamic img.fr-dib.fr-fil {
        margin-left: 0; }
      .dynamic img.fr-dib.fr-fir {
        margin-right: 0; }
    .dynamic img.fr-dii {
      /* Inline */
      display: inline-block;
      float: none;
      vertical-align: bottom;
      margin: 10px 20px;
      max-width: calc(100% - (2 * 20px)); }
      .dynamic img.fr-dii.fr-fil {
        float: left;
        margin: 10px 20px 10px 0;
        max-width: calc(100% - 20px); }
      .dynamic img.fr-dii.fr-fir {
        float: right;
        margin: 10px 0 10px 20px;
        max-width: calc(100% - 20px); }
  .dynamic .fr-img-caption img {
    width: 100%; }
  .dynamic .fr-img-caption.fr-dib {
    /* block */
    display: block;
    margin: 30px auto;
    float: none;
    vertical-align: middle;
    text-align: center; }
    .dynamic .fr-img-caption.fr-dib.fr-fil {
      margin-left: 0;
      text-align: left; }
    .dynamic .fr-img-caption.fr-dib.fr-fir {
      margin-right: 0;
      text-align: right; }
    .dynamic .fr-img-caption.fr-dib .fr-img-wrap .fr-inner {
      display: block; }
  .dynamic .fr-img-caption.fr-dii {
    /* Inline */
    display: inline-block;
    float: none;
    vertical-align: bottom;
    margin: 10px 20px;
    max-width: calc(100% - (2 * 20px));
    text-align: center; }
    .dynamic .fr-img-caption.fr-dii.fr-fil {
      float: left;
      margin-left: 0;
      text-align: left;
      max-width: calc(100% - 20px); }
    .dynamic .fr-img-caption.fr-dii.fr-fir {
      float: right;
      margin-right: 0;
      text-align: right;
      max-width: calc(100% - 20px); }

.ocList .dynamic {
  padding-bottom: 50px; }
  .ocList .dynamic > :first-child {
    margin-top: 0px; }
  .ocList .dynamic > :last-child {
    margin-bottom: 0 !important; }
  .ocList .dynamic > p:last-child > * {
    margin-bottom: 0 !important; }
  .ocList .dynamic h1 {
    margin: 40px 0 20px 0;
    font: 25px/1.2 "Apercu-Bold";
    color: #252b44;
    text-transform: none; }
    @media screen and (max-width: 1200px) {
      .ocList .dynamic h1 {
        font-size: 22px; } }
    @media screen and (max-width: 768px) {
      .ocList .dynamic h1 {
        margin: 30px 0 15px 0;
        font-size: 20px; } }
  .ocList .dynamic h2, .ocList .dynamic h3, .ocList .dynamic h4, .ocList .dynamic h5 {
    margin: 40px 0 15px 0;
    font: 20px/1.2 "Apercu-Bold";
    color: #252b44;
    text-transform: none; }
    @media screen and (max-width: 1200px) {
      .ocList .dynamic h2, .ocList .dynamic h3, .ocList .dynamic h4, .ocList .dynamic h5 {
        font-size: 18px; } }
    @media screen and (max-width: 768px) {
      .ocList .dynamic h2, .ocList .dynamic h3, .ocList .dynamic h4, .ocList .dynamic h5 {
        font-size: 17px;
        margin: 30px 0 10px 0; } }
  .ocList .dynamic .btn {
    padding: 18px 32px; }
    @media screen and (max-width: 1200px) {
      .ocList .dynamic .btn {
        padding: 16px 25px; } }
    @media screen and (max-width: 600px) {
      .ocList .dynamic .btn {
        padding: 14px 22px; } }
  .ocList .dynamic blockquote {
    margin: 30px 0;
    padding-left: 30px; }
    @media screen and (max-width: 768px) {
      .ocList .dynamic blockquote {
        margin: 25px 0;
        padding-left: 20px; } }
    .ocList .dynamic blockquote::before {
      width: 1px; }
    .ocList .dynamic blockquote p, .ocList .dynamic blockquote h1, .ocList .dynamic blockquote h2, .ocList .dynamic blockquote h3, .ocList .dynamic blockquote h4, .ocList .dynamic blockquote pre, .ocList .dynamic blockquote > * {
      margin: 0;
      font: 24px/1.4 "Apercu-Regular";
      color: #526786; }
      @media screen and (max-width: 1200px) {
        .ocList .dynamic blockquote p, .ocList .dynamic blockquote h1, .ocList .dynamic blockquote h2, .ocList .dynamic blockquote h3, .ocList .dynamic blockquote h4, .ocList .dynamic blockquote pre, .ocList .dynamic blockquote > * {
          font-size: 22px; } }
      @media screen and (max-width: 768px) {
        .ocList .dynamic blockquote p, .ocList .dynamic blockquote h1, .ocList .dynamic blockquote h2, .ocList .dynamic blockquote h3, .ocList .dynamic blockquote h4, .ocList .dynamic blockquote pre, .ocList .dynamic blockquote > * {
          font-size: 18px; } }
  .ocList .dynamic p {
    font-size: 16px; }
    @media screen and (max-width: 768px) {
      .ocList .dynamic p {
        font-size: 15px; } }
    @media screen and (max-width: 600px) {
      .ocList .dynamic p {
        font-size: 14px; } }
  .ocList .dynamic .legend, .ocList .dynamic .annotation {
    font-size: 12px; }
    @media screen and (max-width: 768px) {
      .ocList .dynamic .legend, .ocList .dynamic .annotation {
        font-size: 11px; } }
  .ocList .dynamic .legend {
    margin-top: -32px; }
    @media screen and (max-width: 768px) {
      .ocList .dynamic .legend {
        margin-top: -20px; } }
  .ocList .dynamic ul, .ocList .dynamic ol {
    margin: 30px 0; }
    @media screen and (max-width: 768px) {
      .ocList .dynamic ul, .ocList .dynamic ol {
        margin: 25px 0; } }
    .ocList .dynamic ul ul, .ocList .dynamic ul ol, .ocList .dynamic ol ul, .ocList .dynamic ol ol {
      margin: 15px 0; }
    .ocList .dynamic ul > li, .ocList .dynamic ol > li {
      padding-left: 20px;
      font-size: 16px; }
      @media screen and (max-width: 768px) {
        .ocList .dynamic ul > li, .ocList .dynamic ol > li {
          font-size: 15px; } }
      @media screen and (max-width: 600px) {
        .ocList .dynamic ul > li, .ocList .dynamic ol > li {
          font-size: 14px; } }
      .ocList .dynamic ul > li::before, .ocList .dynamic ol > li::before {
        width: 10px; }
    .ocList .dynamic ul ul > li::before, .ocList .dynamic ol ul > li::before {
      width: 5px; }
  .ocList .dynamic table {
    margin: 30px 0;
    font-size: 14px;
    line-height: 16px; }
    @media screen and (max-width: 768px) {
      .ocList .dynamic table {
        margin: 25px 0;
        font-size: 13px; } }
    @media screen and (max-width: 600px) {
      .ocList .dynamic table {
        font-size: 12px; } }

.rubriqueFile {
  max-width: 1100px;
  padding-bottom: 40px; }
  @media screen and (max-width: 768px) {
    .rubriqueFile {
      padding-bottom: 30px; } }
  .rubriqueFile li {
    font-size: 17px;
    line-height: 1.1em; }
    .rubriqueFile li:not(:last-child) {
      margin-bottom: 12px; }
    .rubriqueFile li a {
      position: relative;
      display: block;
      padding: 25px 40px 25px 30px;
      color: #252b44;
      background: #f3f3f3;
      transition: color 300ms; }
      .rubriqueFile li a .icon {
        position: absolute;
        right: 30px;
        top: 50%;
        width: 25px;
        height: 25px;
        stroke: #526786;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%); }
      .rubriqueFile li a:hover {
        color: #526786; }

.hamburger {
  display: none;
  float: right;
  width: 80px;
  height: 80px;
  line-height: 80px;
  background: #252b44;
  text-align: center;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  @media screen and (max-width: 1024px) {
    .hamburger {
      display: block; } }
  @media screen and (max-width: 600px) {
    .hamburger {
      width: 70px;
      height: 70px;
      line-height: 70px; } }
  .hamburger .box {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 18px; }
  .hamburger .inner {
    display: block;
    top: 50%;
    margin-top: -1px; }
    .hamburger .inner, .hamburger .inner::before, .hamburger .inner::after {
      width: 30px;
      height: 2px;
      background-color: #fff;
      border-radius: 0;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease; }
    .hamburger .inner::before, .hamburger .inner::after {
      content: "";
      display: block; }
    .hamburger .inner::before {
      top: -8px; }
    .hamburger .inner::after {
      bottom: -8px; }

.spinAnim .inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .spinAnim .inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .spinAnim .inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.show-sideMenu .inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  @media screen and (max-width: 768px) {
    .show-sideMenu .inner {
      color: #526786; } }
  .show-sideMenu .inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .show-sideMenu .inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

select {
  display: block;
  width: 100%;
  opacity: 0; }

.chosen-container {
  position: relative;
  display: inline-block;
  width: 100% !important;
  font-size: 14px;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.chosen-container .chosen-single {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 100%;
  padding: 0 60px 0 20px;
  border-top: solid 1px #2F3755;
  border-bottom: solid 1px #2F3755;
  font: 12px/60px "Apercu-Medium";
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  white-space: nowrap;
  background-color: #2F3755;
  overflow: hidden;
  z-index: 49;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
  transition: border 300ms, box-shadow 300ms; }
  .chosen-container .chosen-single:hover {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1); }

.chosen-container-active {
  z-index: 50; }

.chosen-container .chosen-single span {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.chosen-container .chosen-single div {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 0;
  height: 0;
  text-align: center;
  margin-top: -3px;
  transition: transform 300ms;
  -webkit-transition: transform 300ms; }

.chosen-container .chosen-single div b {
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #fff transparent transparent transparent;
  transition: border 300ms, transform 300ms; }

.chosen-container-active.chosen-with-drop .chosen-single {
  color: #252b44;
  border-bottom-color: #fff;
  background: #fff;
  box-shadow: none; }

.chosen-container-active.chosen-with-drop .chosen-single div b {
  border-color: #252b44 transparent transparent transparent;
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg); }

.chosen-container .chosen-drop {
  position: absolute;
  top: 0;
  width: 100%;
  border: none;
  border-top: none;
  background: #fff;
  visibility: hidden;
  z-index: 48;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: padding-top 500ms, opacity 500ms, box-shadow 500ms, visibility 0ms ease 500ms; }

.chosen-container.chosen-with-drop .chosen-drop {
  top: 0;
  padding-top: 60px;
  visibility: visible;
  opacity: 1;
  transition: padding 400ms, opacity 400ms; }

.chosen-container .chosen-results {
  position: relative;
  max-height: 198px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.chosen-container .chosen-results li {
  display: block !important;
  margin: 0;
  padding: 13px 20px;
  font: 16px/20px "Apercu-Bold";
  color: #252b44;
  list-style: none;
  word-wrap: break-word;
  -webkit-touch-callout: none;
  transition: color 300ms; }

.chosen-container .chosen-results li:first-child {
  padding-top: 20px; }

.chosen-container .chosen-results li:last-child {
  padding-bottom: 20px; }

.chosen-container .chosen-results li.active-result {
  cursor: pointer;
  color: #252b44; }

.chosen-container .chosen-results li:hover {
  color: #526786; }

.chosen-container .chosen-results li.result-selected {
  font-family: "Apercu-Bold";
  cursor: default;
  color: #526786 !important; }

.chosen-container .chosen-results li.disabled-result {
  display: list-item;
  color: rgba(37, 43, 68, 0.4);
  cursor: default; }

.chosen-disabled {
  opacity: 0.35 !important;
  cursor: default; }

.chosen-disabled .chosen-single {
  cursor: default; }

.chosen-container-single .chosen-search {
  display: none; }

select {
  display: block;
  font: 12px/60px "Apercu-Medium";
  padding: 0 60px 0 20px;
  height: 60px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #2F3755;
  color: #fff;
  letter-spacing: 1px;
  width: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0);
  white-space: nowrap;
  opacity: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  border: none;
  transition: box-shadow 300ms;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none; }
  select:hover {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1); }

select::-ms-expand {
  display: none; }

[data-fadeup] {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 800ms, transform 800ms cubic-bezier(0.215, 0.61, 0.355, 1); }
  [data-fadeup][data-scrollfire="fire"], [data-fadeup][data-multiscrollfire="fire"] {
    opacity: 1;
    transform: none; }

.transition [data-fadeup], .chargement [data-fadeup] {
  opacity: 0 !important;
  transform: translateY(100px) !important; }

.transition [data-scrollfire], .transition [data-multiscrollfire], .chargement [data-scrollfire], .chargement [data-multiscrollfire] {
  transition-delay: 500ms !important; }

[data-scrollfire], [data-multiscrollfire] {
  transition-delay: 0ms; }
  [data-scrollfire][data-delay1], [data-multiscrollfire][data-delay1] {
    transition-delay: 300ms; }
  [data-scrollfire][data-delay2], [data-multiscrollfire][data-delay2] {
    transition-delay: 400ms; }
  [data-scrollfire][data-delay3], [data-multiscrollfire][data-delay3] {
    transition-delay: 500ms; }
  [data-scrollfire][data-delay4], [data-multiscrollfire][data-delay4] {
    transition-delay: 600ms; }
  [data-scrollfire][data-delay5], [data-multiscrollfire][data-delay5] {
    transition-delay: 700ms; }
  [data-scrollfire][data-delay6], [data-multiscrollfire][data-delay6] {
    transition-delay: 800ms; }
  [data-scrollfire][data-delay7], [data-multiscrollfire][data-delay7] {
    transition-delay: 900ms; }

#landing {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: 9998;
  background: #ececec;
  visibility: hidden;
  transition: opacity 400ms, visibility 0ms ease 400ms; }

.chargement #landing {
  opacity: 1;
  visibility: visible; }

#page-transition {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background: #ececec;
  visibility: hidden;
  z-index: 9999; }

.transition #page-transition {
  top: 0;
  bottom: 0;
  opacity: 1;
  visibility: visible;
  transition: opacity 400ms cubic-bezier(0.77, 0, 0.175, 1); }

.end #page-transition {
  top: 0;
  bottom: 0;
  opacity: 0;
  visibility: visible;
  transition: opacity 400ms cubic-bezier(0.77, 0, 0.175, 1); }

form {
  display: block;
  width: 100%; }

div.error {
  position: absolute;
  padding-top: 7px;
  top: calc(100% - 20px);
  font: 12px/1 "Apercu-Regular";
  color: #e13b3b; }

.input-field, .text-field, .select-field, .checkbox-field, .radiobox-field, .file-field {
  position: relative;
  width: 100%;
  margin-bottom: 15px; }
  @media screen and (max-width: 600px) {
    .input-field, .text-field, .select-field, .checkbox-field, .radiobox-field, .file-field {
      margin-bottom: 10px; } }

input.error {
  background: #e13b3b !important;
  color: #fff !important; }

input:-webkit-autofill,
input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  -webkit-text-fill-color: #252b44 !important; }

.input-field {
  text-align: left; }
  .input-field input {
    width: 100%;
    height: 75px;
    padding: 17px 30px 0 30px;
    border: none;
    font: 17px/75px "Apercu-Regular";
    color: #252b44;
    background: #fff;
    transition: background 250ms, color 250ms, margin 300ms; }
    @media screen and (max-width: 1200px) {
      .input-field input {
        height: 60px;
        padding-top: 15px;
        line-height: 60px; } }
    @media screen and (max-width: 1024px) {
      .input-field input {
        font-size: 16px; } }
    @media screen and (max-width: 768px) {
      .input-field input {
        padding-right: 20px;
        padding-left: 20px; } }
    @media screen and (max-width: 600px) {
      .input-field input {
        height: 52px;
        padding-top: 12px;
        font-size: 15px;
        line-height: 52px; } }
    @media screen and (max-width: 500px) {
      .input-field input {
        height: 48px;
        padding: 10px 10px 0 10px;
        line-height: 48px; } }
    .input-field input.valid {
      background: #fff; }
    .input-field input.error {
      margin-bottom: 20px; }
  .input-field label {
    position: absolute;
    top: 0px;
    left: 30px;
    right: 30px;
    font: 17px/75px "Apercu-Regular";
    color: #252b44;
    cursor: text;
    text-align: left;
    transition: all 250ms; }
    @media screen and (max-width: 1200px) {
      .input-field label {
        line-height: 60px; } }
    @media screen and (max-width: 1024px) {
      .input-field label {
        font-size: 16px; } }
    @media screen and (max-width: 768px) {
      .input-field label {
        left: 20px;
        right: 20px; } }
    @media screen and (max-width: 600px) {
      .input-field label {
        font-size: 15px;
        line-height: 52px; } }
    @media screen and (max-width: 500px) {
      .input-field label {
        left: 10px;
        right: 10px;
        line-height: 50px; } }
  .input-field input:focus + label,
  .input-field input.valid + label,
  .input-field input.error + label {
    font-size: 11px;
    line-height: 50px;
    color: #647692;
    cursor: default;
    pointer-events: none; }
    @media screen and (max-width: 1200px) {
      .input-field input:focus + label,
      .input-field input.valid + label,
      .input-field input.error + label {
        line-height: 40px; } }
    @media screen and (max-width: 600px) {
      .input-field input:focus + label,
      .input-field input.valid + label,
      .input-field input.error + label {
        line-height: 32px; } }
  .input-field input.error + label {
    color: #fff; }

/*** File input ***/
.file-field {
  position: relative; }
  .file-field.xMargin {
    margin-bottom: 30px; }
  .file-field input[type=file] {
    position: absolute;
    width: 0;
    height: 0;
    border: none;
    margin: 0;
    box-shadow: none;
    opacity: 0;
    overflow: hidden; }
  .file-field label {
    position: relative;
    display: block;
    width: 100%;
    padding: 0 80px 0 30px;
    border: none;
    font: 17px/75px "Apercu-Regular";
    color: #252b44;
    background: #fff;
    cursor: pointer;
    white-space: nowrap;
    transition: color 300ms; }
    @media screen and (max-width: 1200px) {
      .file-field label {
        height: 60px;
        line-height: 60px;
        padding-right: 60px; } }
    @media screen and (max-width: 1024px) {
      .file-field label {
        font-size: 16px; } }
    @media screen and (max-width: 768px) {
      .file-field label {
        padding-right: 20px;
        padding-left: 20px; } }
    @media screen and (max-width: 600px) {
      .file-field label {
        height: 52px;
        font-size: 15px;
        line-height: 52px; } }
    @media screen and (max-width: 500px) {
      .file-field label {
        height: 48px;
        padding: 0 10px 0 10px;
        line-height: 48px; } }
    .file-field label .icon-stroke {
      position: absolute;
      top: 50%;
      right: 30px;
      margin-top: 0;
      stroke: #526786;
      z-index: 1;
      width: 1.4em;
      height: 1.4em;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%); }
      @media screen and (max-width: 1200px) {
        .file-field label .icon-stroke {
          right: 20px; } }
      @media screen and (max-width: 768px) {
        .file-field label .icon-stroke {
          right: 10px; } }
    .file-field label:hover {
      color: #526786; }
  .file-field .fileAdded + label .noFile {
    display: none; }
  .file-field .fileAdded + label {
    color: #647692; }
  .file-field .error {
    top: 100%; }

.text-field {
  margin-bottom: 30px; }
  @media screen and (max-width: 600px) {
    .text-field {
      margin-bottom: 20px; } }
  .text-field textarea {
    width: 100%;
    height: 170px;
    padding: 35px 30px 0 30px;
    border: none;
    font: 17px/22px "Apercu-Regular";
    color: #252b44;
    background: #fff;
    resize: none;
    overflow: hidden;
    transition: all 500ms, margin 300ms; }
    @media screen and (max-width: 1200px) {
      .text-field textarea {
        padding-top: 25px; } }
    @media screen and (max-width: 1024px) {
      .text-field textarea {
        font-size: 16px; } }
    @media screen and (max-width: 768px) {
      .text-field textarea {
        height: 140px;
        padding-right: 20px;
        padding-left: 20px; } }
    @media screen and (max-width: 600px) {
      .text-field textarea {
        height: 120px;
        padding-top: 22px;
        font-size: 15px; } }
    @media screen and (max-width: 500px) {
      .text-field textarea {
        padding: 18px 10px 0 10px; } }
    .text-field textarea.valid {
      background: #fff; }
    .text-field textarea.error {
      margin-bottom: 20px; }
  .text-field label {
    position: absolute;
    top: 0px;
    left: 30px;
    right: 30px;
    font: 17px/75px "Apercu-Regular";
    color: #252b44;
    cursor: text;
    text-align: left;
    transition: all 250ms; }
    @media screen and (max-width: 1200px) {
      .text-field label {
        line-height: 60px; } }
    @media screen and (max-width: 1024px) {
      .text-field label {
        font-size: 16px; } }
    @media screen and (max-width: 768px) {
      .text-field label {
        left: 20px;
        right: 20px; } }
    @media screen and (max-width: 600px) {
      .text-field label {
        font-size: 15px;
        line-height: 52px; } }
    @media screen and (max-width: 500px) {
      .text-field label {
        left: 10px;
        right: 10px;
        line-height: 50px; } }
  .text-field textarea:focus + label,
  .text-field textarea.valid + label {
    font-size: 11px;
    line-height: 50px;
    color: #647692;
    cursor: default;
    pointer-events: none; }
    @media screen and (max-width: 1200px) {
      .text-field textarea:focus + label,
      .text-field textarea.valid + label {
        line-height: 40px; } }
    @media screen and (max-width: 600px) {
      .text-field textarea:focus + label,
      .text-field textarea.valid + label {
        line-height: 32px; } }
    @media screen and (max-width: 500px) {
      .text-field textarea:focus + label,
      .text-field textarea.valid + label {
        line-height: 28px; } }
  .text-field textarea.error {
    background: #e13b3b !important;
    color: #fff !important; }
  .text-field textarea.error + label {
    color: #fff; }

.searchField {
  position: relative;
  width: 100%;
  /*&.notEmpty {
        label {
            top: 0;
            opacity: 0;
            visibility: hidden;
            line-height: 60px;
        }

        .clearSearch {
            opacity: 1;
            transition: opacity 400ms;
            cursor: pointer;
        }
    }*/
  /*input:focus + label,
    input:active + label{
        color: red !important;
    }*/ }
  .searchField input {
    width: 100%;
    height: 55px;
    padding: 0 65px 0 0;
    border: none;
    border-bottom: 2px solid white;
    font-size: 25px;
    line-height: 55px;
    color: white;
    background: transparent !important;
    transition: border 300ms ease; }
    .searchField input:focus, .searchField input.valid, .searchField input:hover {
      border-bottom: 2px solid rgba(255, 255, 255, 0.6); }
    @media screen and (max-width: 768px) {
      .searchField input {
        height: 50px;
        font-size: rem(22);
        line-height: 50px; } }
    @media screen and (max-width: 600px) {
      .searchField input {
        font-size: rem(18); } }
  .searchField #formSearch-error {
    top: 100%;
    color: white; }
  .searchField label {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 25px;
    line-height: 55px;
    font-weight: 500;
    color: white;
    cursor: text;
    pointer-events: none;
    transition: top 300ms, font-size 300ms; }
    @media screen and (max-width: 768px) {
      .searchField label {
        font-size: rem(22);
        line-height: 50px; } }
    @media screen and (max-width: 600px) {
      .searchField label {
        font-size: rem(20); } }
  .searchField input.valid + label,
  .searchField input.error + label,
  .searchField input.notEmpty + label,
  .searchField input:focus + label,
  .searchField input:active + label {
    top: -20px;
    font-size: 15px;
    line-height: 30px; }
  .searchField span {
    position: absolute;
    top: 50%;
    right: 0;
    cursor: pointer;
    transform: translateY(-50%);
    transition: opacity 300ms; }
    .searchField span:hover {
      opacity: 0.5; }
    .searchField span svg {
      width: 23px;
      height: 23px;
      stroke-width: 3px;
      fill: none;
      stroke: white; }
      @media screen and (max-width: 425px) {
        .searchField span svg {
          width: 25px;
          height: 25px; } }

#shareModal {
  position: fixed;
  left: 50%;
  top: 50%;
  margin: 20px 0 0 0;
  padding: 80px 0px;
  width: 500px;
  max-width: calc(100% - 40px);
  max-height: 80%;
  text-align: center;
  background: #252b44;
  z-index: 800;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transition: opacity 250ms, margin 250ms, visibility 0ms ease 250ms; }
  @media screen and (max-width: 768px) {
    #shareModal {
      width: 420px;
      padding: 70px; } }
  @media screen and (max-width: 600px) {
    #shareModal {
      padding: 70px 20px 50px 20px; } }
  @media screen and (max-width: 500px) {
    #shareModal {
      padding: 60px 20px 40px 20px; } }
  #shareModal h3 {
    color: #fff; }
    @media screen and (max-width: 768px) {
      #shareModal h3 {
        font-size: 22px; } }
  #shareModal #shareCloseBtn {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 14px; }
    #shareModal #shareCloseBtn .icon-stroke {
      stroke: #fff;
      vertical-align: middle; }
    #shareModal #shareCloseBtn:hover .icon-stroke {
      stroke: #647692;
      transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg); }
  #shareModal .medias-sociaux {
    display: inline-block;
    margin-top: 30px; }
    #shareModal .medias-sociaux a {
      position: relative;
      float: left;
      display: block;
      width: 45px;
      height: 45px;
      margin: 4px;
      font-size: 13px;
      line-height: 45px;
      text-align: center;
      background: rgba(100, 118, 146, 0.15);
      z-index: 1;
      transition: background 300ms;
      cursor: pointer; }
      #shareModal .medias-sociaux a:hover {
        background: #fff; }
        #shareModal .medias-sociaux a:hover .icon {
          fill: #252b44; }
      #shareModal .medias-sociaux a .icon {
        fill: #fff;
        vertical-align: middle; }
      @media screen and (max-width: 335px) {
        #shareModal .medias-sociaux a:nth-child(3) {
          clear: left; } }

.show-shareModal #shareModal {
  opacity: 1;
  margin-top: 0;
  visibility: visible;
  transition: opacity 400ms, margin 400ms;
  transition: opacity 400ms, margin 400ms; }

header {
  position: relative;
  z-index: 60; }

#topNav {
  background: #252b44; }
  @media screen and (max-width: 1024px) {
    #topNav {
      display: none; } }
  #topNav ul {
    float: left; }
    #topNav ul li {
      float: left;
      font: 12px/40px "Apercu-Regular"; }
      #topNav ul li:first-child {
        margin-left: -20px; }
      #topNav ul li a {
        display: inline-block;
        cursor: pointer;
        padding: 0 20px;
        color: #fff;
        transition: color 300ms; }
        #topNav ul li a:hover {
          color: #647692; }
  #topNav #lang {
    float: right;
    display: block;
    height: 40px;
    margin-right: -20px;
    padding: 0 20px;
    font: 12px/40px "Apercu-Regular";
    color: #fff;
    transition: color 300ms;
    cursor: pointer; }
    #topNav #lang:hover {
      color: #647692; }

@media screen and (max-width: 1024px) {
  #mainNav {
    padding-left: 0;
    padding-right: 0; } }

#mainNav > div {
  position: relative;
  background: #fff;
  z-index: 1; }
  #mainNav > div #mainLogo {
    float: left;
    display: block;
    padding: 0 30px;
    line-height: 90px; }
    @media screen and (max-width: 1400px) {
      #mainNav > div #mainLogo {
        padding-right: 25px; } }
    @media screen and (max-width: 1200px) {
      #mainNav > div #mainLogo {
        padding-right: 20px; } }
    @media screen and (max-width: 1024px) {
      #mainNav > div #mainLogo {
        padding-right: 0;
        padding-left: 20px;
        line-height: 80px; } }
    @media screen and (max-width: 600px) {
      #mainNav > div #mainLogo {
        padding-left: 10px;
        line-height: 70px; } }
    #mainNav > div #mainLogo .icon {
      width: 100px;
      height: 62px;
      vertical-align: middle;
      fill: #252b44;
      margin-top: -2px; }
      @media screen and (max-width: 600px) {
        #mainNav > div #mainLogo .icon {
          width: 80px;
          height: 50px; } }
  #mainNav > div #mainMenu {
    float: left; }
    @media screen and (max-width: 1024px) {
      #mainNav > div #mainMenu {
        display: none; } }
    #mainNav > div #mainMenu ul > li {
      float: left;
      font: 18px/90px "Apercu-Regular"; }
      #mainNav > div #mainMenu ul > li > a {
        display: block;
        padding: 0 30px;
        color: #252b44; }
        @media screen and (max-width: 1400px) {
          #mainNav > div #mainMenu ul > li > a {
            padding: 0 25px; } }
        @media screen and (max-width: 1200px) {
          #mainNav > div #mainMenu ul > li > a {
            padding: 0 20px; } }
        @media screen and (max-width: 1100px) {
          #mainNav > div #mainMenu ul > li > a {
            padding: 0 12px; } }
    #mainNav > div #mainMenu #navBar {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 4px;
      width: 0;
      background: #252b44; }
  #mainNav > div #mainBtn {
    float: right;
    display: block;
    padding: 0 40px;
    font: 15px/90px "Apercu-Medium";
    color: #fff;
    background: #526786;
    transition: background 300ms; }
    #mainNav > div #mainBtn:hover {
      background: #647692; }
    @media screen and (max-width: 1200px) {
      #mainNav > div #mainBtn {
        padding: 0 30px; } }
    @media screen and (max-width: 1024px) {
      #mainNav > div #mainBtn {
        line-height: 80px; } }
    @media screen and (max-width: 600px) {
      #mainNav > div #mainBtn {
        right: 70px;
        line-height: 70px; } }
    @media screen and (max-width: 500px) {
      #mainNav > div #mainBtn {
        display: none; } }
  #mainNav > div #langMobile {
    float: right;
    display: none;
    padding: 0 20px;
    font: 15px/80px "Apercu-Medium";
    color: #647692;
    transition: color 300ms; }
    @media screen and (max-width: 1024px) {
      #mainNav > div #langMobile {
        display: block; } }
    @media screen and (max-width: 600px) {
      #mainNav > div #langMobile {
        line-height: 70px; } }
    #mainNav > div #langMobile:hover {
      color: #252b44; }

#portesDrop {
  position: absolute;
  left: 0;
  top: 100%;
  right: 0;
  max-height: 0px;
  background: #ececec;
  z-index: -1;
  overflow: hidden;
  opacity: 0;
  transition: max-height 600ms cubic-bezier(0.19, 1, 0.22, 1), opacity 650ms; }
  #portesDrop #dropCycle {
    padding: 35px 70px; }
    #portesDrop #dropCycle .slick-track {
      margin-left: 0;
      margin-right: 0; }
    @media screen and (max-width: 1400px) {
      #portesDrop #dropCycle {
        padding: 25px 45px; } }
    @media screen and (max-width: 1200px) {
      #portesDrop #dropCycle {
        padding: 20px 30px; } }

.show-mainNavDrop #portesDrop {
  max-height: 500px;
  opacity: 1;
  transition: max-height 1.2s cubic-bezier(0.19, 1, 0.22, 1), opacity 300ms; }
  .show-mainNavDrop #portesDrop #dropCycle > div {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    transition: transform 500ms ease 400ms, opacity 400ms ease 400ms;
    -webkit-transition: -webkit-transform 500ms ease 400ms, opacity 400ms ease 400ms; }

#dropCycle > div {
  opacity: 0;
  transform: translateY(50px);
  -webkit-transform: translateY(50px);
  transition: transform 0ms ease 500ms, opacity 0ms ease 500ms;
  -webkit-transition: -webkit-transform 0ms ease 500ms, opacity 0ms ease 500ms; }
  #dropCycle > div .porteBox h3 {
    font-size: 25px; }
    @media screen and (max-width: 1400px) {
      #dropCycle > div .porteBox h3 {
        font-size: 22px; } }
    @media screen and (max-width: 1200px) {
      #dropCycle > div .porteBox h3 {
        font-size: 20px; } }

footer {
  background: #252b44; }
  @media screen and (max-width: 600px) {
    footer {
      text-align: center; } }
  footer #footerMenu {
    padding: 60px 0 0 0; }
    @media screen and (max-width: 500px) {
      footer #footerMenu {
        padding-top: 50px; } }
    footer #footerMenu > a {
      float: left;
      display: block; }
      @media screen and (max-width: 600px) {
        footer #footerMenu > a {
          float: none;
          display: inline-block;
          margin-bottom: 20px; } }
      footer #footerMenu > a .icon {
        width: 100px;
        height: 62px;
        fill: #fff; }
    footer #footerMenu ul {
      float: left;
      margin: 0 0 0 30px; }
      @media screen and (max-width: 1200px) {
        footer #footerMenu ul {
          margin-left: 25px; } }
      @media screen and (max-width: 768px) {
        footer #footerMenu ul {
          margin-left: 15px; } }
      @media screen and (max-width: 600px) {
        footer #footerMenu ul {
          float: none;
          margin: 0 0 20px 0;
          text-align: center; } }
      footer #footerMenu ul li {
        float: left; }
        @media screen and (max-width: 600px) {
          footer #footerMenu ul li {
            float: none; } }
        footer #footerMenu ul li a {
          display: block;
          height: 62px;
          padding: 0 30px;
          font: 15px/62px "Apercu-Regular";
          color: #fff;
          transition: color 300ms; }
          @media screen and (max-width: 1200px) {
            footer #footerMenu ul li a {
              padding: 0 25px; } }
          @media screen and (max-width: 1024px) {
            footer #footerMenu ul li a {
              height: 31px;
              line-height: 31px; } }
          @media screen and (max-width: 768px) {
            footer #footerMenu ul li a {
              padding: 0 15px; } }
          footer #footerMenu ul li a:hover {
            color: #647692; }
        @media screen and (max-width: 1024px) {
          footer #footerMenu ul li:nth-child(3) {
            clear: left; } }
        @media screen and (max-width: 768px) {
          footer #footerMenu ul li:nth-child(3) {
            clear: none; } }
        @media screen and (max-width: 768px) {
          footer #footerMenu ul li:nth-child(4) {
            clear: left; } }
    footer #footerMenu > div {
      position: relative;
      float: right;
      padding: 7px 0; }
      @media screen and (max-width: 600px) {
        footer #footerMenu > div {
          float: none;
          display: inline-block;
          padding: 0; } }
      footer #footerMenu > div a.rs {
        float: left;
        display: block;
        height: 48px;
        width: 48px;
        margin: 0 0 0 10px;
        line-height: 48px;
        text-align: center;
        background: #526786;
        transition: background 300ms; }
        @media screen and (max-width: 600px) {
          footer #footerMenu > div a.rs {
            width: 42px;
            height: 42px;
            line-height: 42px;
            margin: 0 5px; } }
        footer #footerMenu > div a.rs .icon {
          fill: #fff;
          vertical-align: middle; }
        footer #footerMenu > div a.rs:hover {
          background: #fff; }
          footer #footerMenu > div a.rs:hover .icon {
            fill: #526786; }
      footer #footerMenu > div img {
        position: absolute;
        top: -15px;
        left: -175px;
        height: 90px; }
        @media (max-width: 1250px) {
          footer #footerMenu > div img {
            display: none; } }
  footer #copy {
    padding: 45px 0 30px 0; }
    @media screen and (max-width: 768px) {
      footer #copy {
        padding: 40px 0 20px 0; } }
    @media screen and (max-width: 600px) {
      footer #copy {
        padding: 40px 0 15px 0; } }
    footer #copy p, footer #copy a {
      display: inline-block;
      margin: 0;
      vertical-align: middle;
      font: 12px/20px "Apercu-Regular";
      color: rgba(255, 255, 255, 0.5); }
      @media screen and (max-width: 600px) {
        footer #copy p, footer #copy a {
          display: block; } }
    footer #copy a {
      float: right;
      transition: color 300ms; }
      @media screen and (max-width: 600px) {
        footer #copy a {
          float: none;
          display: inline-block; } }
      footer #copy a:hover {
        color: #fff; }
    @media screen and (max-width: 600px) {
      footer #copy p {
        margin-bottom: 5px; } }

#sideMenu {
  position: fixed;
  left: 0;
  right: -16px;
  top: 80px;
  bottom: 100%;
  overflow: hidden;
  pointer-events: none;
  transition: bottom 0ms ease 700ms, top 500ms cubic-bezier(0.23, 1, 0.32, 1); }
  @media screen and (max-width: 600px) {
    #sideMenu {
      top: 70px; } }
  #sideMenu .sidepanelContainer {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 70%;
    max-width: 850px;
    background: #ececec;
    z-index: -1;
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    pointer-events: auto;
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    transform-origin: 100% 50%;
    -webkit-transform-origin: 100% 50%;
    transition: transform 650ms cubic-bezier(0.775, 0, 0.211, 1) 50ms, box-shadow 300ms;
    -webkit-transition: -webkit-transform 650ms cubic-bezier(0.775, 0, 0.211, 1) 50ms, box-shadow 300ms; }
    @media screen and (max-width: 768px) {
      #sideMenu .sidepanelContainer {
        width: calc(100% - 200px); } }
    @media screen and (max-width: 600px) {
      #sideMenu .sidepanelContainer {
        width: 100%;
        max-width: none; } }
    #sideMenu .sidepanelContainer div {
      display: none; }
    #sideMenu .sidepanelContainer a {
      position: relative;
      display: block;
      margin: 0;
      padding: 15px 60px;
      font: 30px/1 "Apercu-Bold";
      color: #252b44;
      text-transform: none;
      letter-spacing: 0;
      cursor: pointer;
      transition: color 300ms;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
      @media screen and (max-width: 768px) {
        #sideMenu .sidepanelContainer a {
          padding: 12px 50px;
          font-size: 26px; } }
      @media screen and (max-width: 500px) {
        #sideMenu .sidepanelContainer a {
          padding: 12px 20px;
          font-size: 24px; } }
      #sideMenu .sidepanelContainer a:hover {
        color: #526786; }
    #sideMenu .sidepanelContainer > ul:first-child {
      margin: 0 0 15px 0;
      opacity: 0;
      transform: translateX(40px);
      -webkit-transform: translateX(40px);
      transition: opacity 450ms, transform 0ms ease 650ms;
      -webkit-transition: opacity 450ms, -webkit-transform 0ms ease 650ms; }
      #sideMenu .sidepanelContainer > ul:first-child > li:first-child {
        padding-top: 60px; }
        @media screen and (max-width: 600px) {
          #sideMenu .sidepanelContainer > ul:first-child > li:first-child {
            padding-top: 40px; } }
        @media screen and (max-width: 500px) {
          #sideMenu .sidepanelContainer > ul:first-child > li:first-child {
            padding-top: 30px; } }
      #sideMenu .sidepanelContainer > ul:first-child > li > div ul {
        padding: 20px 0;
        border-top: 10px solid #ececec;
        border-bottom: 10px solid #ececec;
        background: #ececec;
        transition: background 0ms ease 500ms; }
        @media screen and (max-width: 768px) {
          #sideMenu .sidepanelContainer > ul:first-child > li > div ul {
            padding: 15px 0; } }
        #sideMenu .sidepanelContainer > ul:first-child > li > div ul li {
          opacity: 0;
          transform: translateX(40px);
          -webkit-transform: translateX(40px);
          transition: opacity 300ms, transform 0ms ease 500ms;
          -webkit-transition: opacity 300ms, -webkit-transform 0ms ease 500ms; }
          #sideMenu .sidepanelContainer > ul:first-child > li > div ul li a {
            padding-top: 10px;
            padding-bottom: 10px;
            font: 22px/1.1 "Apercu-Medium";
            color: #526786; }
            @media screen and (max-width: 600px) {
              #sideMenu .sidepanelContainer > ul:first-child > li > div ul li a {
                font-size: 20px; } }
            @media screen and (max-width: 500px) {
              #sideMenu .sidepanelContainer > ul:first-child > li > div ul li a {
                font-size: 17px; } }
            #sideMenu .sidepanelContainer > ul:first-child > li > div ul li a:hover {
              color: #252b44; }
      #sideMenu .sidepanelContainer > ul:first-child > li.open > a {
        color: #526786; }
      #sideMenu .sidepanelContainer > ul:first-child > li.open > div ul {
        background: #fff;
        transition: background 600ms; }
        #sideMenu .sidepanelContainer > ul:first-child > li.open > div ul li {
          opacity: 1;
          transform: translateX(0px);
          -webkit-transform: translateX(0);
          transition: opacity 600ms ease 100ms, transform 600ms ease 100ms;
          -webkit-transition: opacity 600ms ease 100ms, -webkit-transform 600ms ease 100ms; }
    #sideMenu .sidepanelContainer > ul:last-child {
      padding-bottom: 35px;
      opacity: 0;
      transform: translateX(40px);
      -webkit-transform: translateX(40px);
      transition: opacity 450ms, transform 0ms ease 650ms;
      -webkit-transition: opacity 450ms, -webkit-transform 0ms ease 650ms; }
      #sideMenu .sidepanelContainer > ul:last-child li a {
        padding-top: 10px;
        padding-bottom: 10px;
        font: 18px/1.1 "Apercu-Medium"; }
        @media screen and (max-width: 600px) {
          #sideMenu .sidepanelContainer > ul:last-child li a {
            padding-top: 7px;
            padding-bottom: 7px;
            font-size: 16px; } }

.show-sideMenu {
  height: 100%;
  overflow-y: scroll; }
  .show-sideMenu body {
    height: 100%;
    overflow: hidden; }
  .show-sideMenu #sideMenu {
    bottom: 0;
    transition: top 500ms cubic-bezier(0.23, 1, 0.32, 1); }
    .show-sideMenu #sideMenu .sidepanelContainer {
      box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.2);
      transform: scaleX(1);
      -webkit-transform: scaleX(1);
      transition-delay: 0;
      -webkit-transition-delay: 0; }
      .show-sideMenu #sideMenu .sidepanelContainer > ul {
        opacity: 1;
        transform: translateX(0);
        -webkit-transform: translateX(0);
        transition: opacity 1s ease 500ms, transform 1s ease 500ms;
        -webkit-transition: opacity 1s ease 500ms, -webkit-transform 1s ease 500ms; }
        .show-sideMenu #sideMenu .sidepanelContainer > ul:last-child {
          transition: opacity 1s ease 700ms, transform 1s ease 700ms;
          -webkit-transition: opacity 1s ease 700ms, -webkit-transform 1s ease 700ms; }

.list > li {
  position: relative;
  margin-bottom: 15px;
  background: #fff; }
  @media screen and (max-width: 600px) {
    .list > li {
      margin-bottom: 10px; } }
  .list > li:last-child {
    margin: 0; }
  .list > li > a {
    position: relative;
    display: block;
    padding: 45px 120px 45px 60px;
    transition: background 300ms; }
    @media screen and (max-width: 1600px) {
      .list > li > a {
        padding-left: 40px;
        padding-right: 90px; } }
    @media screen and (max-width: 1024px) {
      .list > li > a {
        padding: 40px 80px 40px 30px; } }
    @media screen and (max-width: 768px) {
      .list > li > a {
        padding: 35px 70px 35px 20px; } }
    @media screen and (max-width: 600px) {
      .list > li > a {
        padding: 30px 60px 30px 20px; } }
    @media screen and (max-width: 500px) {
      .list > li > a {
        padding: 28px 60px 28px 20px; } }
    .list > li > a.hasImage {
      padding-left: 195px; }
      @media screen and (max-width: 1024px) {
        .list > li > a.hasImage {
          padding-left: 190px; } }
      @media screen and (max-width: 768px) {
        .list > li > a.hasImage {
          padding-left: 185px; } }
      @media screen and (max-width: 600px) {
        .list > li > a.hasImage {
          padding-left: 30px; } }
      .list > li > a.hasImage .image {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 150px;
        left: 0;
        background-position: center;
        background-size: cover; }
        @media screen and (max-width: 600px) {
          .list > li > a.hasImage .image {
            display: none; } }
    .list > li > a:hover {
      background: #252b44; }
      .list > li > a:hover h4 {
        color: #fff; }
      .list > li > a:hover p {
        color: #526786; }
        .list > li > a:hover p span {
          color: #fff; }
    .list > li > a h4 {
      margin: 0;
      font-size: 25px;
      line-height: 1.1;
      transition: color 300ms; }
      @media screen and (max-width: 1200px) {
        .list > li > a h4 {
          font-size: 22px; } }
      @media screen and (max-width: 768px) {
        .list > li > a h4 {
          font-size: 18px; } }
    .list > li > a p {
      margin: 0 0 10px 0;
      font: 13px/16px "Apercu-Medium";
      color: #a5a5a5;
      transition: color 300ms; }
      @media screen and (max-width: 768px) {
        .list > li > a p {
          margin-bottom: 7px; } }
      .list > li > a p span {
        color: #252b44;
        transition: color 300ms; }

.list.ocList > li > a::after {
  position: absolute;
  right: 50px;
  top: 50%;
  display: block;
  width: 20px;
  height: 2px;
  margin-top: -1;
  content: '';
  background: #252b44;
  transition: background 300ms; }
  @media screen and (max-width: 1600px) {
    .list.ocList > li > a::after {
      right: 30px; } }
  @media screen and (max-width: 1024px) {
    .list.ocList > li > a::after {
      right: 20px; } }
  @media screen and (max-width: 768px) {
    .list.ocList > li > a::after {
      width: 16px; } }
  @media screen and (max-width: 600px) {
    .list.ocList > li > a::after {
      right: 15px; } }

.list.ocList > li > a::before {
  position: absolute;
  right: 60px;
  top: 50%;
  display: block;
  width: 2px;
  height: 20px;
  margin: -9px -1px 0 0;
  content: '';
  background: #252b44;
  transition: opacity 300ms, background 300ms; }
  @media screen and (max-width: 1600px) {
    .list.ocList > li > a::before {
      right: 40px; } }
  @media screen and (max-width: 1024px) {
    .list.ocList > li > a::before {
      right: 30px; } }
  @media screen and (max-width: 768px) {
    .list.ocList > li > a::before {
      height: 16px;
      margin-top: -7px;
      right: 28px; } }
  @media screen and (max-width: 600px) {
    .list.ocList > li > a::before {
      right: 23px; } }

.list.ocList > li > a:hover::before, .list.ocList > li > a:hover::after {
  background: #fff; }

.list.ocList .ocListCt {
  position: relative;
  display: none;
  padding: 0 140px 0 60px;
  opacity: 0;
  transform: translateY(10px);
  -webkit-transform: translateY(10px);
  transition: opacity 200ms cubic-bezier(0.19, 1, 0.22, 1), transform 200ms cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transition: opacity 200ms cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 200ms cubic-bezier(0.19, 1, 0.22, 1); }
  @media screen and (max-width: 1600px) {
    .list.ocList .ocListCt {
      padding-right: 100px;
      padding-left: 40px; } }
  @media screen and (max-width: 1400px) {
    .list.ocList .ocListCt {
      padding-left: 40px; } }
  @media screen and (max-width: 1024px) {
    .list.ocList .ocListCt {
      padding: 0 90px 0 30px; } }
  @media screen and (max-width: 768px) {
    .list.ocList .ocListCt {
      padding-left: 20px; } }
  @media screen and (max-width: 600px) {
    .list.ocList .ocListCt {
      padding-right: 20px;
      padding-bottom: 20px; } }
  .list.ocList .ocListCt > .dynamic {
    max-width: 1100px; }
    .list.ocList .ocListCt > .dynamic > :first-child {
      padding-top: 50px; }
      @media screen and (max-width: 1024px) {
        .list.ocList .ocListCt > .dynamic > :first-child {
          padding-top: 40px; } }
      @media screen and (max-width: 768px) {
        .list.ocList .ocListCt > .dynamic > :first-child {
          padding-top: 30px; } }
    .list.ocList .ocListCt > .dynamic > :last-child {
      margin-bottom: 0;
      padding-bottom: 0; }

.list.ocList .open > a {
  background: #252b44; }
  .list.ocList .open > a::before {
    opacity: 0; }
  .list.ocList .open > a::after {
    background: #fff; }
  .list.ocList .open > a h4 {
    color: #fff; }
  .list.ocList .open > a p {
    color: #526786; }
    .list.ocList .open > a p span {
      color: #fff; }

.list.ocList .open .ocListCt {
  opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  transition: transform 400ms ease 100ms, opacity 400ms ease 100ms;
  -webkit-transition: -webkit-transform 400ms ease 100ms, opacity 400ms ease 100ms; }

.list.doc li a,
.list.doc .collapsedDoc a {
  background-color: white;
  padding: 35px 120px 35px 45px; }
  @media screen and (max-width: 500px) {
    .list.doc li a,
    .list.doc .collapsedDoc a {
      padding: 35px 75px 35px 25px; } }
  .list.doc li a span,
  .list.doc .collapsedDoc a span {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100px; }
    @media screen and (max-width: 500px) {
      .list.doc li a span,
      .list.doc .collapsedDoc a span {
        width: 75px; } }
    .list.doc li a span svg,
    .list.doc .collapsedDoc a span svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      stroke: #526786;
      width: 30px;
      height: 30px; }
      @media screen and (max-width: 500px) {
        .list.doc li a span svg,
        .list.doc .collapsedDoc a span svg {
          width: 24px;
          height: 24px; } }
  .list.doc li a .centerize h2,
  .list.doc .collapsedDoc a .centerize h2 {
    margin-bottom: 0;
    font-size: 28px; }
    @media screen and (max-width: 1024px) {
      .list.doc li a .centerize h2,
      .list.doc .collapsedDoc a .centerize h2 {
        font-size: 24px; } }
    @media screen and (max-width: 768px) {
      .list.doc li a .centerize h2,
      .list.doc .collapsedDoc a .centerize h2 {
        font-size: 20px; } }
    @media screen and (max-width: 600px) {
      .list.doc li a .centerize h2,
      .list.doc .collapsedDoc a .centerize h2 {
        font-size: 18px; } }
    @media screen and (max-width: 500px) {
      .list.doc li a .centerize h2,
      .list.doc .collapsedDoc a .centerize h2 {
        font-size: 16px; } }

.list.doc li.withThumb a,
.list.doc .collapsedDoc.withThumb a {
  padding: 35px 120px 35px 130px; }
  @media screen and (max-width: 500px) {
    .list.doc li.withThumb a,
    .list.doc .collapsedDoc.withThumb a {
      padding: 35px 75px 35px 25px; } }
  .list.doc li.withThumb a .image,
  .list.doc .collapsedDoc.withThumb a .image {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 110px;
    background-size: cover;
    background-position: center; }
    @media screen and (max-width: 500px) {
      .list.doc li.withThumb a .image,
      .list.doc .collapsedDoc.withThumb a .image {
        display: none; } }

h3.categTitle {
  margin-bottom: 30px; }

.newList a {
  position: relative; }
  .newList a .icons-box {
    position: absolute;
    right: 60px;
    top: 50%;
    margin-top: -10px;
    display: block;
    height: 20px;
    font-size: 25px;
    overflow: hidden; }
    @media screen and (max-width: 1600px) {
      .newList a .icons-box {
        right: 40px; } }
    @media screen and (max-width: 1024px) {
      .newList a .icons-box {
        right: 20px; } }
    @media screen and (max-width: 600px) {
      .newList a .icons-box {
        font-size: 20px; } }
    .newList a .icons-box span {
      display: block;
      width: 100%;
      height: 100%;
      transform: translateY(-5px);
      -webkit-transform: translateY(-5px);
      transition: transform 800ms cubic-bezier(0.23, 1, 0.32, 1);
      -webkit-transition: -webkit-transform 800ms cubic-bezier(0.23, 1, 0.32, 1); }
      @media screen and (max-width: 600px) {
        .newList a .icons-box span {
          transform: translateY(-3px);
          -webkit-transform: translateY(-3px); } }
      .newList a .icons-box span .icon {
        vertical-align: middle; }
    .newList a .icons-box .icon-left {
      position: absolute;
      left: -200%;
      top: 0;
      bottom: 0; }
  .newList a:hover {
    color: #526786; }
    .newList a:hover .icons-box span {
      transform: translate(200%, -5px);
      -webkit-transform: -webkit-translateX(200%, -5px); }
      @media screen and (max-width: 600px) {
        .newList a:hover .icons-box span {
          transform: translate(200%, -3px);
          -webkit-transform: -webkit-translateX(200%, -3px); } }
      .newList a:hover .icons-box span .icon {
        stroke: #fff; }

.list.ocList.doc .rubrique:hover > a {
  background-color: #252b44; }

.list.ocList.doc .collapsedDoc {
  border-bottom: rgba(37, 43, 68, 0.1) 1px solid; }
  .list.ocList.doc .collapsedDoc:last-child {
    border-bottom: 0; }
  .list.ocList.doc .collapsedDoc a {
    display: block;
    position: relative; }

.list.ocList.doc .ocListCt {
  padding: 0; }

.galerie .galerieWrapper {
  position: relative;
  max-width: 1000px; }
  .galerie .galerieWrapper::after {
    display: table;
    content: '';
    clear: both; }
  .galerie .galerieWrapper img {
    width: 100%; }
  .galerie .galerieWrapper a::after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: '';
    background: rgba(0, 0, 0, 0.15);
    transition: background 250ms; }
  .galerie .galerieWrapper a:hover::after {
    background: rgba(0, 0, 0, 0); }
  .galerie .galerieWrapper > a {
    position: relative;
    display: block;
    float: left;
    width: calc(100% - 320px);
    height: 300px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; }
    @media screen and (max-width: 768px) {
      .galerie .galerieWrapper > a {
        height: 240px;
        width: calc(100% - 250px); } }
    @media screen and (max-width: 600px) {
      .galerie .galerieWrapper > a {
        width: 100%;
        margin: 0 0 10px 0; } }
    @media screen and (max-width: 500px) {
      .galerie .galerieWrapper > a {
        height: 200px; } }
  .galerie .galerieWrapper ul {
    float: right;
    width: 320px; }
    @media screen and (max-width: 768px) {
      .galerie .galerieWrapper ul {
        width: 250px; } }
    @media screen and (max-width: 600px) {
      .galerie .galerieWrapper ul {
        float: none;
        width: calc(100% + 10px);
        margin-left: -5px; } }
    .galerie .galerieWrapper ul li {
      display: none;
      float: left;
      width: 50%;
      padding: 0 0 0 20px; }
      @media screen and (max-width: 768px) {
        .galerie .galerieWrapper ul li {
          padding-left: 10px; } }
      @media screen and (max-width: 600px) {
        .galerie .galerieWrapper ul li {
          width: 25%;
          max-width: 90px;
          padding: 0 5px 0 5px; } }
      .galerie .galerieWrapper ul li:first-child, .galerie .galerieWrapper ul li:nth-child(2) {
        padding-bottom: 20px; }
        @media screen and (max-width: 768px) {
          .galerie .galerieWrapper ul li:first-child, .galerie .galerieWrapper ul li:nth-child(2) {
            padding-bottom: 10px; } }
        @media screen and (max-width: 600px) {
          .galerie .galerieWrapper ul li:first-child, .galerie .galerieWrapper ul li:nth-child(2) {
            padding-bottom: 0px; } }
      .galerie .galerieWrapper ul li:first-child, .galerie .galerieWrapper ul li:nth-child(2), .galerie .galerieWrapper ul li:nth-child(3), .galerie .galerieWrapper ul li:nth-child(4) {
        display: block; }
      .galerie .galerieWrapper ul li:nth-child(4) a::before {
        position: absolute;
        left: 50%;
        top: 50%;
        content: '+';
        font-size: 40px;
        font-family: Georgia;
        color: #fff;
        z-index: 1;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%); }
      .galerie .galerieWrapper ul li a {
        position: relative;
        display: block;
        line-height: 0;
        width: 100%;
        padding-top: 100%;
        background-size: cover;
        background-position: center; }

/*** INSIDE RUBRIQUE ***/
.ocListCt .galerie {
  padding-bottom: 60px; }
  @media screen and (max-width: 1600px) {
    .ocListCt .galerie {
      padding-bottom: 40px; } }
  @media screen and (max-width: 1024px) {
    .ocListCt .galerie {
      padding-bottom: 30px; } }
  @media screen and (max-width: 768px) {
    .ocListCt .galerie {
      padding-bottom: 20px; } }
  .ocListCt .galerie .galerieWrapper {
    max-width: 800px; }
    .ocListCt .galerie .galerieWrapper > a {
      height: 240px;
      width: calc(100% - 250px); }
      @media screen and (max-width: 600px) {
        .ocListCt .galerie .galerieWrapper > a {
          height: 260px;
          width: 100%; } }
      @media screen and (max-width: 500px) {
        .ocListCt .galerie .galerieWrapper > a {
          height: 240px; } }
    .ocListCt .galerie .galerieWrapper ul {
      width: 250px; }
      @media screen and (max-width: 600px) {
        .ocListCt .galerie .galerieWrapper ul {
          width: calc(100% + 10px); } }
      .ocListCt .galerie .galerieWrapper ul li {
        padding-left: 10px; }
        @media screen and (max-width: 600px) {
          .ocListCt .galerie .galerieWrapper ul li {
            width: 25%;
            max-width: 90px;
            padding: 0 5px 0 5px; } }
        .ocListCt .galerie .galerieWrapper ul li:first-child, .ocListCt .galerie .galerieWrapper ul li:nth-child(2) {
          padding-bottom: 10px; }
          @media screen and (max-width: 600px) {
            .ocListCt .galerie .galerieWrapper ul li:first-child, .ocListCt .galerie .galerieWrapper ul li:nth-child(2) {
              padding-bottom: 0px; } }

#cta_partenaires {
  position: relative;
  background: #252b44 url("../../assets/images/content/cta_partenaires.jpg") no-repeat center bottom;
  background-size: cover; }
  #cta_partenaires .col24 {
    height: 615px;
    line-height: 615px;
    text-align: center; }
    @media screen and (max-width: 1400px) {
      #cta_partenaires .col24 {
        height: 550px;
        line-height: 550px; } }
    @media screen and (max-width: 1200px) {
      #cta_partenaires .col24 {
        height: 500px;
        line-height: 500px; } }
    @media screen and (max-width: 1024px) {
      #cta_partenaires .col24 {
        height: 450px;
        line-height: 450px; } }
    @media screen and (max-width: 768px) {
      #cta_partenaires .col24 {
        height: 380px;
        line-height: 380px; } }
    @media screen and (max-width: 600px) {
      #cta_partenaires .col24 {
        height: 300px;
        line-height: 300px; } }
    @media screen and (max-width: 500px) {
      #cta_partenaires .col24 {
        height: auto;
        line-height: normal;
        padding: 50px 10px; } }
    #cta_partenaires .col24 h1 {
      color: #fff; }
    #cta_partenaires .col24 .btn {
      margin: 0 10px; }
      @media screen and (max-width: 600px) {
        #cta_partenaires .col24 .btn {
          margin: 0 5px; } }
      @media screen and (max-width: 500px) {
        #cta_partenaires .col24 .btn {
          margin: 0; }
          #cta_partenaires .col24 .btn.btnWhite {
            margin-top: 10px; } }

#cta_infolettre {
  background: #252b44 url("../../assets/images/content/cta_infolettre.jpg") no-repeat center bottom;
  background-size: cover; }
  #cta_infolettre .col24 {
    height: 540px;
    line-height: 540px;
    text-align: center; }
    @media screen and (max-width: 1400px) {
      #cta_infolettre .col24 {
        height: 500px;
        line-height: 500px; } }
    @media screen and (max-width: 1024px) {
      #cta_infolettre .col24 {
        height: 450px;
        line-height: 450px; } }
    @media screen and (max-width: 768px) {
      #cta_infolettre .col24 {
        height: 380px;
        line-height: 380px; } }
    @media screen and (max-width: 600px) {
      #cta_infolettre .col24 {
        height: 300px;
        line-height: 300px; } }
    @media screen and (max-width: 500px) {
      #cta_infolettre .col24 {
        height: auto;
        line-height: normal;
        padding: 50px 10px; } }
    #cta_infolettre .col24 h5 {
      color: #fff; }
    #cta_infolettre .col24 h2 {
      max-width: 500px;
      margin: 0 auto 50px auto;
      color: #fff; }
      @media screen and (max-width: 1400px) {
        #cta_infolettre .col24 h2 {
          margin-bottom: 40px; } }
      @media screen and (max-width: 1200px) {
        #cta_infolettre .col24 h2 {
          margin-bottom: 35px; } }
      @media screen and (max-width: 600px) {
        #cta_infolettre .col24 h2 {
          max-width: 400px; } }
      @media screen and (max-width: 500px) {
        #cta_infolettre .col24 h2 {
          margin-bottom: 30px; } }
    #cta_infolettre .col24 .centerize {
      width: 100%;
      max-width: 720px; }
      @media screen and (max-width: 1024px) {
        #cta_infolettre .col24 .centerize {
          max-width: 600px; } }
      @media screen and (max-width: 768px) {
        #cta_infolettre .col24 .centerize {
          max-width: 500px; } }
      @media screen and (max-width: 500px) {
        #cta_infolettre .col24 .centerize #infolettreForm {
          text-align: center; } }
      #cta_infolettre .col24 .centerize #infolettreForm .input-field {
        margin-bottom: 10px; }
        @media screen and (max-width: 500px) {
          #cta_infolettre .col24 .centerize #infolettreForm .input-field {
            display: inline-block;
            width: 100%;
            max-width: 300px;
            text-align: center; } }
        #cta_infolettre .col24 .centerize #infolettreForm .input-field input {
          margin-bottom: 0 !important; }
        #cta_infolettre .col24 .centerize #infolettreForm .input-field div.error {
          top: 100%; }
    #cta_infolettre .col24 #infolettreCourriel {
      float: left;
      width: 70%; }
      @media screen and (max-width: 500px) {
        #cta_infolettre .col24 #infolettreCourriel {
          float: none;
          margin-bottom: 10px;
          width: 100%; } }
    #cta_infolettre .col24 #infolettreLabel {
      right: calc(30% + 30px); }
    #cta_infolettre .col24 #infolettreFormSubmit {
      position: relative;
      float: left;
      width: 30%;
      padding: 0;
      border: none;
      font: 15px/75px "Apercu-Medium";
      color: #fff;
      text-align: center;
      background: #526786;
      cursor: pointer;
      z-index: 1; }
      @media screen and (max-width: 1200px) {
        #cta_infolettre .col24 #infolettreFormSubmit {
          line-height: 60px; } }
      @media screen and (max-width: 600px) {
        #cta_infolettre .col24 #infolettreFormSubmit {
          line-height: 52px; } }
      @media screen and (max-width: 500px) {
        #cta_infolettre .col24 #infolettreFormSubmit {
          float: none;
          width: 100%;
          max-width: 150px;
          line-height: 48px;
          margin-top: 10px; } }
      #cta_infolettre .col24 #infolettreFormSubmit::after {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 0;
        content: '';
        background: #252b44;
        z-index: -1;
        transition: width 700ms cubic-bezier(0.19, 1, 0.22, 1); }
        @media screen and (max-width: 500px) {
          #cta_infolettre .col24 #infolettreFormSubmit::after {
            left: 0;
            right: auto; } }
      #cta_infolettre .col24 #infolettreFormSubmit:hover::after {
        width: 100%; }

@media screen and (max-width: 600px) {
  #cta_carrieres {
    background: #ececec; } }

#cta_carrieres #carriereMain {
  height: 460px;
  margin-bottom: 25px;
  line-height: 460px; }
  @media screen and (max-width: 1600px) {
    #cta_carrieres #carriereMain {
      height: 400px;
      line-height: 400px; } }
  @media screen and (max-width: 1024px) {
    #cta_carrieres #carriereMain {
      height: 350px;
      line-height: 350px;
      margin-bottom: 20px; } }
  @media screen and (max-width: 768px) {
    #cta_carrieres #carriereMain {
      height: 330px;
      line-height: 330px;
      margin-bottom: 15px; } }
  @media screen and (max-width: 600px) {
    #cta_carrieres #carriereMain {
      height: auto;
      margin: 0;
      padding-bottom: 0;
      line-height: normal; } }
  #cta_carrieres #carriereMain .carriere-pic1 {
    height: 100%;
    line-height: 0;
    background: url("../../assets/images/content/cta_carrieres1.jpg") no-repeat center center;
    background-size: cover; }
    @media screen and (max-width: 600px) {
      #cta_carrieres #carriereMain .carriere-pic1 {
        height: 300px;
        line-height: 300px;
        margin-bottom: 30px; } }
    @media screen and (max-width: 500px) {
      #cta_carrieres #carriereMain .carriere-pic1 {
        height: 270px;
        line-height: 270px;
        margin-bottom: 25px; } }
  #cta_carrieres #carriereMain p {
    max-width: 370px;
    margin-bottom: 30px; }
    @media screen and (max-width: 768px) {
      #cta_carrieres #carriereMain p {
        margin-bottom: 25px; } }
    @media screen and (max-width: 600px) {
      #cta_carrieres #carriereMain p {
        max-width: none; } }

#cta_carrieres .carriere-pic2 div {
  float: right;
  width: 360px;
  height: 360px;
  margin-right: 25px;
  background: url("../../assets/images/content/cta_carrieres2.jpg") no-repeat center center; }
  @media screen and (max-width: 1600px) {
    #cta_carrieres .carriere-pic2 div {
      height: 300px;
      width: 300px; } }
  @media screen and (max-width: 1024px) {
    #cta_carrieres .carriere-pic2 div {
      height: 250px;
      width: 250px;
      margin-right: 20px; } }
  @media screen and (max-width: 768px) {
    #cta_carrieres .carriere-pic2 div {
      height: 200px;
      width: 200px;
      margin-right: 15px; } }

#cta_carrieres .carriere-pic3 {
  height: 460px;
  background: url("../../assets/images/content/cta_carrieres3.jpg") no-repeat center center; }
  @media screen and (max-width: 1600px) {
    #cta_carrieres .carriere-pic3 {
      height: 400px; } }
  @media screen and (max-width: 1024px) {
    #cta_carrieres .carriere-pic3 {
      height: 350px; } }
  @media screen and (max-width: 768px) {
    #cta_carrieres .carriere-pic3 {
      height: 300px; } }
  @media screen and (max-width: 600px) {
    #cta_carrieres .carriere-pic3 {
      height: 300px; } }

#cta_portfolio {
  background: #252b44 url("../../assets/images/content/cta_portfolio.jpg") no-repeat center bottom;
  background-size: cover; }
  #cta_portfolio .col24 {
    position: relative;
    display: block;
    height: 540px;
    line-height: 540px;
    z-index: 1; }
    @media screen and (max-width: 1600px) {
      #cta_portfolio .col24 {
        height: 500px;
        line-height: 500px; } }
    @media screen and (max-width: 1400px) {
      #cta_portfolio .col24 {
        height: 450px;
        line-height: 450px; } }
    @media screen and (max-width: 1024px) {
      #cta_portfolio .col24 {
        height: 420px;
        line-height: 420px; } }
    @media screen and (max-width: 768px) {
      #cta_portfolio .col24 {
        height: 350px;
        line-height: 350px; } }
    @media screen and (max-width: 600px) {
      #cta_portfolio .col24 {
        height: 300px;
        line-height: 300px; } }
    @media screen and (max-width: 500px) {
      #cta_portfolio .col24 {
        height: 260px;
        line-height: 260px; } }
    #cta_portfolio .col24 h1 {
      margin: 0 0 30px -5px;
      color: #fff; }
      @media screen and (max-width: 1024px) {
        #cta_portfolio .col24 h1 {
          margin-bottom: 20px; } }
      @media screen and (max-width: 600px) {
        #cta_portfolio .col24 h1 {
          margin-bottom: 15px; } }
    #cta_portfolio .col24 h5 {
      color: #fff; }
    #cta_portfolio .col24 .link {
      margin: 0;
      padding-right: 0; }
      #cta_portfolio .col24 .link .icons-box {
        right: auto;
        left: 0; }
        #cta_portfolio .col24 .link .icons-box .icon-stroke {
          stroke: #fff; }
    #cta_portfolio .col24::after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 20%;
      content: '';
      background: #526786;
      z-index: -1;
      transition: width 500ms; }
      @media screen and (max-width: 1200px) {
        #cta_portfolio .col24::after {
          width: 25%; } }
      @media screen and (max-width: 600px) {
        #cta_portfolio .col24::after {
          width: 30%; } }
    #cta_portfolio .col24:hover .icons-box span {
      transform: translate(200%, -5px);
      -webkit-transform: -webkit-translateX(200%, -5px); }
      @media screen and (max-width: 600px) {
        #cta_portfolio .col24:hover .icons-box span {
          transform: translate(200%, -3px);
          -webkit-transform: -webkit-translateX(200%, -3px); } }
    #cta_portfolio .col24:hover::after {
      width: calc(20% + 45px); }
      @media screen and (max-width: 1200px) {
        #cta_portfolio .col24:hover::after {
          width: calc(25% + 45px); } }
      @media screen and (max-width: 600px) {
        #cta_portfolio .col24:hover::after {
          width: calc(30% + 45px); } }

#cta_porte {
  background: #fff;
  background-size: cover;
  display: block;
  height: 900px;
  position: relative;
  padding-right: 480px; }
  @media screen and (max-width: 1200px) {
    #cta_porte {
      padding-right: 380px; } }
  @media screen and (max-width: 985px) {
    #cta_porte {
      padding-right: 0px;
      height: 100%; } }
  #cta_porte #porteCycle, #cta_porte .slick-list, #cta_porte .slick-track, #cta_porte .slick-slide {
    height: 100%; }
  @media screen and (max-width: 985px) {
    #cta_porte .slick-track {
      position: relative;
      left: 0;
      top: 0px; } }
  #cta_porte .slide {
    padding: 120px 200px;
    text-align: center; }
  #cta_porte .sidebar {
    position: absolute;
    width: 480px;
    right: 0;
    top: 0;
    background: #252b44;
    height: 100%;
    padding: 75px 50px; }
    @media screen and (max-width: 1200px) {
      #cta_porte .sidebar {
        padding: 75px 25px;
        width: 380px; } }
    @media screen and (max-width: 985px) {
      #cta_porte .sidebar {
        height: auto;
        bottom: 0;
        width: 100%;
        top: auto;
        padding: 20px;
        position: relative; } }
  #cta_porte .slick-arrow {
    position: absolute;
    z-index: 1;
    transform: none; }
    #cta_porte .slick-arrow .icon-stroke {
      stroke: #252b44; }
    #cta_porte .slick-arrow:before {
      display: none; }
  #cta_porte .arrowBtn {
    background: #ececec !important; }
  #cta_porte .slick-next:before, #cta_porte .slick-prev:before {
    color: #252b44; }
  #cta_porte .slick-next {
    right: 100px; }
    @media screen and (max-width: 1200px) {
      #cta_porte .slick-next {
        right: 90px; } }
    @media screen and (max-width: 1100px) {
      #cta_porte .slick-next {
        right: 70px; } }
    @media screen and (max-width: 1024px) {
      #cta_porte .slick-next {
        right: 70px; } }
    @media screen and (max-width: 768px) {
      #cta_porte .slick-next {
        right: 30px; } }
  #cta_porte .slick-prev {
    left: 100px;
    transform: rotate(180deg); }
    @media screen and (max-width: 768px) {
      #cta_porte .slick-prev {
        left: 30px; } }
  #cta_porte .spec {
    color: #fff;
    display: flex; }
    @media screen and (max-width: 985px) {
      #cta_porte .spec {
        margin-bottom: 20px; } }
    #cta_porte .spec .spec-number {
      font: 17px/1.2 "Apercu-Regular"; }
    #cta_porte .spec .spec-text {
      color: #fff;
      font: 14px/1.6 "Apercu-Regular";
      max-width: 315px; }
      @media screen and (max-width: 985px) {
        #cta_porte .spec .spec-text {
          max-width: none;
          padding: 0px 10px;
          margin-bottom: 0px; } }
  #cta_porte .slick-slide {
    position: relative; }
    @media screen and (max-width: 985px) {
      #cta_porte .slick-slide {
        height: 400px; } }
    #cta_porte .slick-slide img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: inline-block;
      max-height: 700px; }
      @media screen and (max-width: 1500px) {
        #cta_porte .slick-slide img {
          max-width: 330px; } }
      @media screen and (max-width: 985px) {
        #cta_porte .slick-slide img {
          max-height: 350px;
          max-width: 300px; } }
      @media screen and (max-width: 500px) {
        #cta_porte .slick-slide img {
          max-height: 275px;
          max-width: 180px; } }

#cta_equipe {
  background: #252b44; }
  @media screen and (max-width: 500px) {
    #cta_equipe {
      padding-right: 0px; } }
  #cta_equipe h5 {
    color: #526786; }
  #cta_equipe h1 {
    color: #fff; }
    @media screen and (max-width: 500px) {
      #cta_equipe h1 {
        padding-right: 20px; } }
  #cta_equipe #teamCycle {
    margin-left: -15px;
    width: calc(100% + 30px); }
    @media screen and (max-width: 1200px) {
      #cta_equipe #teamCycle {
        margin-left: -10px;
        width: calc(100% + 20px); } }
    @media screen and (max-width: 768px) {
      #cta_equipe #teamCycle {
        margin-left: -5px;
        width: calc(100% + 10px); } }
    @media screen and (max-width: 500px) {
      #cta_equipe #teamCycle .slick-list {
        padding-right: 35%; } }
    @media screen and (max-width: 400px) {
      #cta_equipe #teamCycle .slick-list {
        padding-right: 25%; } }
    #cta_equipe #teamCycle .slick-slide {
      margin: 0 15px;
      overflow: hidden; }
      @media screen and (max-width: 1200px) {
        #cta_equipe #teamCycle .slick-slide {
          margin: 0 10px; } }
      @media screen and (max-width: 768px) {
        #cta_equipe #teamCycle .slick-slide {
          margin: 0 5px; } }
      #cta_equipe #teamCycle .slick-slide span {
        position: relative;
        display: block; }
        #cta_equipe #teamCycle .slick-slide span img {
          width: 100%; }
        #cta_equipe #teamCycle .slick-slide span div {
          position: absolute;
          z-index: 2;
          left: 30px;
          bottom: 30px; }
          @media screen and (max-width: 1024px) {
            #cta_equipe #teamCycle .slick-slide span div {
              left: 20px;
              bottom: 20px; } }
          #cta_equipe #teamCycle .slick-slide span div h5 {
            margin-bottom: 5px;
            font-family: "Apercu-Bold";
            color: #fff; }
          #cta_equipe #teamCycle .slick-slide span div p {
            margin: 0;
            font-size: 15px;
            color: #fff; }
            #cta_equipe #teamCycle .slick-slide span div p a {
              transition: box-shadow 300ms;
              box-shadow: inset 0px -10px 0px -1px rgba(255, 255, 255, 0.3); }
              #cta_equipe #teamCycle .slick-slide span div p a:hover {
                box-shadow: inset 0px -16px 0px -1px rgba(255, 255, 255, 0.3); }
            #cta_equipe #teamCycle .slick-slide span div p.coords {
              font-size: 14px;
              margin-top: 15px; }
        #cta_equipe #teamCycle .slick-slide span .overlay {
          pointer-events: none;
          display: block;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          position: absolute;
          z-index: 1;
          background: linear-gradient(0deg, rgba(37, 43, 68, 0.7) 0%, rgba(37, 43, 68, 0) 80%); }
    #cta_equipe #teamCycle .slick-prev {
      left: -28px;
      transform: rotate(180deg) translate(0, 50%);
      z-index: 5; }
      @media screen and (max-width: 1400px) {
        #cta_equipe #teamCycle .slick-prev {
          left: -20px; } }
      @media screen and (max-width: 1024px) {
        #cta_equipe #teamCycle .slick-prev {
          left: -10px; } }
      @media screen and (max-width: 768px) {
        #cta_equipe #teamCycle .slick-prev {
          left: 0px; } }
      @media screen and (max-width: 500px) {
        #cta_equipe #teamCycle .slick-prev {
          display: none !important; } }
      #cta_equipe #teamCycle .slick-prev::before {
        display: none; }
    #cta_equipe #teamCycle .slick-next {
      right: -28px; }
      @media screen and (max-width: 1400px) {
        #cta_equipe #teamCycle .slick-next {
          right: -20px; } }
      @media screen and (max-width: 1024px) {
        #cta_equipe #teamCycle .slick-next {
          right: -10px; } }
      @media screen and (max-width: 768px) {
        #cta_equipe #teamCycle .slick-next {
          right: 0px; } }
      @media screen and (max-width: 500px) {
        #cta_equipe #teamCycle .slick-next {
          display: none !important; } }
      #cta_equipe #teamCycle .slick-next::before {
        display: none; }

#grid_equipe {
  background: #252b44; }
  #grid_equipe #membres {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -35px;
    margin-left: -2%;
    margin-right: -2%; }
    #grid_equipe #membres .membre {
      width: 33%;
      padding: 0 2%;
      padding-bottom: 30px;
      position: relative;
      display: block; }
      @media screen and (max-width: 768px) {
        #grid_equipe #membres .membre {
          width: 50%; } }
      @media screen and (max-width: 600px) {
        #grid_equipe #membres .membre {
          width: 100%; } }
      #grid_equipe #membres .membre .membre_wrapper {
        position: relative; }
        #grid_equipe #membres .membre .membre_wrapper img {
          width: 100%; }
        #grid_equipe #membres .membre .membre_wrapper div {
          position: absolute;
          z-index: 2;
          left: 30px;
          bottom: 30px; }
          @media screen and (max-width: 1024px) {
            #grid_equipe #membres .membre .membre_wrapper div {
              left: 20px;
              bottom: 20px; } }
          #grid_equipe #membres .membre .membre_wrapper div h5 {
            margin-bottom: 5px;
            font-family: "Apercu-Bold";
            color: #fff; }
          #grid_equipe #membres .membre .membre_wrapper div p {
            margin: 0;
            font-size: 15px;
            color: #fff; }
            #grid_equipe #membres .membre .membre_wrapper div p a {
              transition: box-shadow 300ms;
              box-shadow: inset 0px -10px 0px -1px rgba(255, 255, 255, 0.3); }
              #grid_equipe #membres .membre .membre_wrapper div p a:hover {
                box-shadow: inset 0px -16px 0px -1px rgba(255, 255, 255, 0.3); }
            #grid_equipe #membres .membre .membre_wrapper div p.coords {
              font-size: 14px;
              margin-top: 15px; }
        #grid_equipe #membres .membre .membre_wrapper .overlay {
          pointer-events: none;
          display: block;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          position: absolute;
          z-index: 1;
          background: linear-gradient(0deg, rgba(37, 43, 68, 0.7) 0%, rgba(37, 43, 68, 0) 80%); }

#cta_actualites {
  background: #ececec; }

#cta_vitraux {
  background: #252b44; }
  #cta_vitraux .intro {
    max-width: 380px;
    position: relative;
    z-index: 2; }
    #cta_vitraux .intro h5 {
      color: #fff; }
    #cta_vitraux .intro h1 {
      color: #fff; }
      @media screen and (max-width: 500px) {
        #cta_vitraux .intro h1 {
          padding-right: 20px; } }
    #cta_vitraux .intro p {
      color: #fff; }
  #cta_vitraux .vitraux {
    position: relative;
    margin-top: -180px;
    margin-bottom: -75px;
    display: flex;
    flex-wrap: wrap; }
    @media screen and (max-width: 1024px) {
      #cta_vitraux .vitraux {
        margin-top: auto;
        margin-bottom: auto;
        padding-top: 50px; } }
    #cta_vitraux .vitraux .vitrail {
      width: 50%;
      display: block;
      margin-bottom: 75px;
      text-align: right;
      padding-left: 3%; }
      @media screen and (max-width: 1024px) {
        #cta_vitraux .vitraux .vitrail {
          width: 100%; } }
      #cta_vitraux .vitraux .vitrail .wrapper {
        max-width: 550px;
        width: 100%;
        display: inline-block;
        text-align: left; }
        #cta_vitraux .vitraux .vitrail .wrapper .photo {
          display: block;
          width: 100%;
          padding-top: 75%;
          position: relative;
          overflow: hidden; }
          @media screen and (max-width: 768px) {
            #cta_vitraux .vitraux .vitrail .wrapper .photo {
              padding-top: 60%; } }
          #cta_vitraux .vitraux .vitrail .wrapper .photo > span {
            background-position: center;
            background-size: cover;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            transform: scale(1);
            transition: transform cubic-bezier(0.23, 1, 0.32, 1) 0.3s; }
        #cta_vitraux .vitraux .vitrail .wrapper .lien {
          display: block;
          color: #fff;
          font-weight: bold;
          font-size: 25px;
          margin-top: 22px; }
          #cta_vitraux .vitraux .vitrail .wrapper .lien svg {
            stroke: #fff;
            margin-left: 25px;
            transition: margin-left cubic-bezier(0.23, 1, 0.32, 1) 0.3s; }
        #cta_vitraux .vitraux .vitrail .wrapper:hover .photo > span {
          transform: scale(1.1); }
        #cta_vitraux .vitraux .vitrail .wrapper:hover .lien svg {
          margin-left: 35px; }
      #cta_vitraux .vitraux .vitrail:first-child {
        margin-left: 50%; }
        @media screen and (max-width: 1024px) {
          #cta_vitraux .vitraux .vitrail:first-child {
            margin-left: 0; } }
      #cta_vitraux .vitraux .vitrail:nth-child(even) {
        text-align: left;
        margin-top: -11%; }
        @media screen and (max-width: 1024px) {
          #cta_vitraux .vitraux .vitrail:nth-child(even) {
            margin-top: 6%; } }
        @media screen and (max-width: 768px) {
          #cta_vitraux .vitraux .vitrail:nth-child(even) {
            margin-top: 0; } }
      #cta_vitraux .vitraux .vitrail:nth-child(1), #cta_vitraux .vitraux .vitrail:nth-child(2) {
        padding-left: 3%;
        padding-right: 6%; }
      #cta_vitraux .vitraux .vitrail:nth-child(3), #cta_vitraux .vitraux .vitrail:nth-child(4) {
        padding-left: 6%;
        padding-right: 3%; }
      @media screen and (max-width: 1024px) {
        #cta_vitraux .vitraux .vitrail:nth-child(1), #cta_vitraux .vitraux .vitrail:nth-child(3) {
          padding-left: 0;
          padding-right: 3%; }
        #cta_vitraux .vitraux .vitrail:nth-child(2), #cta_vitraux .vitraux .vitrail:nth-child(4) {
          padding-left: 3%;
          padding-right: 0; } }
      @media screen and (max-width: 768px) {
        #cta_vitraux .vitraux .vitrail {
          margin-bottom: 40px; }
          #cta_vitraux .vitraux .vitrail:nth-child(1), #cta_vitraux .vitraux .vitrail:nth-child(2), #cta_vitraux .vitraux .vitrail:nth-child(3), #cta_vitraux .vitraux .vitrail:nth-child(4) {
            padding-left: 0;
            padding-right: 0; }
          #cta_vitraux .vitraux .vitrail:last-child {
            margin-bottom: 0; } }

#fabriqueAuQuebec h1 {
  margin-top: 80px;
  position: relative; }
  #fabriqueAuQuebec h1 span {
    display: block; }
  #fabriqueAuQuebec h1 .fabriqueQuebec {
    position: absolute;
    top: -100px;
    left: 70%;
    pointer-events: none; }
    @media screen and (max-width: 1400px) {
      #fabriqueAuQuebec h1 .fabriqueQuebec {
        left: 68%; }
        #fabriqueAuQuebec h1 .fabriqueQuebec img {
          width: 150px; } }
    @media screen and (max-width: 1024px) {
      #fabriqueAuQuebec h1 .fabriqueQuebec {
        top: -70px;
        left: 65%; }
        #fabriqueAuQuebec h1 .fabriqueQuebec img {
          width: 115px; } }
    @media screen and (max-width: 600px) {
      #fabriqueAuQuebec h1 .fabriqueQuebec {
        top: -55px; }
        #fabriqueAuQuebec h1 .fabriqueQuebec img {
          width: 90px; } }

.porteBox {
  position: relative;
  float: left;
  display: block;
  line-height: 0;
  background: #eee;
  overflow: hidden; }
  .porteBox img {
    width: 100%;
    transition: transform 400ms; }
  .porteBox div {
    position: absolute;
    z-index: 2;
    left: 30px;
    right: 20px;
    bottom: 40px; }
    @media screen and (max-width: 1200px) {
      .porteBox div {
        left: 20px;
        bottom: 30px; } }
  .porteBox h6 {
    color: #fff; }
  .porteBox h3 {
    margin: 0;
    color: #fff; }
  .porteBox:hover img {
    transform: scale(1.05);
    -webkit-transform: scale(1.05); }
  .porteBox::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #252b44;
    background: linear-gradient(0deg, #252b44 0%, rgba(37, 43, 68, 0) 100%);
    z-index: 1; }

@media screen and (max-width: 500px) {
  .portesCycle .slick-list {
    padding-right: 35%; } }

@media screen and (max-width: 400px) {
  .portesCycle .slick-list {
    padding-right: 25%; } }

.portesCycle .slick-slide {
  margin: 0 5px;
  padding: 5px;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    .portesCycle .slick-slide {
      margin: 0; } }

.portesCycle .slick-prev {
  display: none !important; }

.portesCycle .slick-next {
  right: -28px; }
  @media screen and (max-width: 1400px) {
    .portesCycle .slick-next {
      right: -20px; } }
  @media screen and (max-width: 768px) {
    .portesCycle .slick-next {
      right: -5px; } }
  @media screen and (max-width: 500px) {
    .portesCycle .slick-next {
      display: none !important; } }
  .portesCycle .slick-next::before {
    display: none; }

.portesCycle .icon-stroke {
  stroke: #526786; }

.mainNew {
  position: relative;
  display: block;
  min-height: 480px;
  line-height: 480px;
  background: #fff; }
  @media screen and (max-width: 1400px) {
    .mainNew {
      min-height: 400px;
      line-height: 400px; } }
  @media screen and (max-width: 1200px) {
    .mainNew {
      min-height: 360px;
      line-height: 360px; } }
  @media screen and (max-width: 1024px) {
    .mainNew {
      min-height: 280px;
      line-height: 280px; } }
  @media screen and (max-width: 768px) {
    .mainNew {
      min-height: 220px;
      line-height: 220px; } }
  @media screen and (max-width: 600px) {
    .mainNew {
      min-height: 0;
      line-height: normal; } }
  .mainNew .centerize {
    width: calc(100% - 600px);
    max-width: 800px; }
    @media screen and (max-width: 1400px) {
      .mainNew .centerize {
        width: calc(100% - 500px); } }
    @media screen and (max-width: 1200px) {
      .mainNew .centerize {
        width: calc(100% - 450px); } }
    @media screen and (max-width: 1024px) {
      .mainNew .centerize {
        width: calc(100% - 350px); } }
    @media screen and (max-width: 768px) {
      .mainNew .centerize {
        width: calc(100% - 275px); } }
    @media screen and (max-width: 600px) {
      .mainNew .centerize {
        width: auto;
        padding: 30px 20px; } }
    .mainNew .centerize h4 {
      margin: 0; }
      @media screen and (max-width: 700px) {
        .mainNew .centerize h4 {
          font-size: 18px; } }
      @media screen and (max-width: 600px) {
        .mainNew .centerize h4 {
          font-size: 20px; } }
      @media screen and (max-width: 500px) {
        .mainNew .centerize h4 {
          font-size: 19px; } }
  .mainNew .mainNewPic {
    position: absolute;
    right: 0;
    top: 0;
    width: 600px;
    height: 480px;
    overflow: hidden; }
    @media screen and (max-width: 1400px) {
      .mainNew .mainNewPic {
        width: 500px;
        height: 400px; } }
    @media screen and (max-width: 1200px) {
      .mainNew .mainNewPic {
        width: 450px;
        height: 360px; } }
    @media screen and (max-width: 1024px) {
      .mainNew .mainNewPic {
        width: 350px;
        height: 280px; } }
    @media screen and (max-width: 768px) {
      .mainNew .mainNewPic {
        width: 275px;
        height: 220px; } }
    @media screen and (max-width: 600px) {
      .mainNew .mainNewPic {
        position: static;
        right: auto;
        top: auto;
        width: 100%;
        height: 280px; } }
    @media screen and (max-width: 500px) {
      .mainNew .mainNewPic {
        height: 260px; } }
    .mainNew .mainNewPic div {
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      transition: transform 400ms; }
  .mainNew:hover .mainNewPic div {
    transform: scale(1.05);
    -webkit-transform: scale(1.05); }

#home .logoQC {
  position: absolute;
  top: -100px;
  right: 200px;
  transform: scale(0.9); }
  @media screen and (max-width: 1600px) {
    #home .logoQC {
      right: 125px; } }
  @media screen and (max-width: 1400px) {
    #home .logoQC {
      right: 80px; } }
  @media screen and (max-height: 850px) {
    #home .logoQC {
      top: -75px;
      right: 215px;
      transform: scale(0.8); } }
  @media screen and (max-height: 850px) and (max-width: 1625px) {
    #home .logoQC {
      right: 200px; } }
  @media screen and (max-height: 850px) and (max-width: 1600px) {
    #home .logoQC {
      right: 125px; } }
  @media screen and (max-height: 850px) and (max-width: 1400px) {
    #home .logoQC {
      right: 85px; } }
  @media screen and (max-height: 850px) and (max-width: 1200px) {
    #home .logoQC {
      right: 70px; } }
  @media screen and (max-height: 800px) {
    #home .logoQC {
      top: -75px;
      right: 215px;
      transform: scale(0.8); } }
  @media screen and (max-height: 800px) and (max-width: 1625px) {
    #home .logoQC {
      right: 200px; } }
  @media screen and (max-height: 800px) and (max-width: 1600px) {
    #home .logoQC {
      right: 125px; } }
  @media screen and (max-height: 800px) and (max-width: 1400px) {
    #home .logoQC {
      right: 85px; } }
  @media screen and (max-height: 800px) and (max-width: 1200px) {
    #home .logoQC {
      right: 70px; } }
  @media screen and (max-width: 1024px) {
    #home .logoQC {
      right: 25px; } }
  @media screen and (max-width: 800px) {
    #home .logoQC {
      right: 0px; } }
  @media screen and (max-width: 800px) {
    #home .logoQC {
      display: none; } }

#home #mainNav {
  position: absolute;
  left: 0;
  right: 0;
  top: 40px; }
  @media screen and (max-width: 1024px) {
    #home #mainNav {
      position: relative;
      left: auto;
      right: auto;
      top: auto; } }

@media screen and (max-width: 768px) {
  #home #homeBanner {
    height: auto !important;
    line-height: normal !important;
    text-align: center; } }

@media screen and (max-width: 600px) {
  #home #homeBanner {
    text-align: left; } }

@media screen and (min-width: 1400px) and (max-height: 700px) {
  #home #homeBanner {
    min-height: 700px;
    line-height: 700px !important; } }

@media screen and (min-width: 768px) and (max-width: 1399px) and (max-height: 600px) {
  #home #homeBanner {
    min-height: 600px;
    line-height: 600px !important; } }

@media screen and (max-width: 1600px) {
  #home #homeBanner .xPadding {
    padding-left: 75px;
    padding-right: 75px; } }

@media screen and (max-width: 1400px) {
  #home #homeBanner .xPadding {
    padding-left: 50px;
    padding-right: 50px; } }

@media screen and (max-width: 1024px) {
  #home #homeBanner .xPadding {
    padding-left: 30px;
    padding-right: 30px; } }

@media screen and (max-width: 768px) {
  #home #homeBanner .xPadding {
    width: 100%;
    max-width: 550px;
    padding: 40px 20px 50px 20px; } }

@media screen and (max-width: 600px) {
  #home #homeBanner .xPadding {
    padding: 40px 20px; }
    #home #homeBanner .xPadding h1 {
      margin-bottom: 20px; } }

@media screen and (max-width: 500px) {
  #home #homeBanner .xPadding {
    padding: 35px 20px 40px 20px; } }

#home #homeBanner > div:first-child {
  position: relative; }
  #home #homeBanner > div:first-child #scrollDown {
    position: absolute;
    left: 160px;
    bottom: 50px;
    font: 15px/20px "Apercu-Medium";
    color: #252b44;
    transition: color 300ms; }
    @media screen and (max-width: 1600px) {
      #home #homeBanner > div:first-child #scrollDown {
        left: 75px; } }
    @media screen and (max-width: 1400px) {
      #home #homeBanner > div:first-child #scrollDown {
        left: 50px; } }
    @media screen and (max-width: 1024px) {
      #home #homeBanner > div:first-child #scrollDown {
        left: 30px;
        bottom: 40px; } }
    @media screen and (max-width: 768px) {
      #home #homeBanner > div:first-child #scrollDown {
        display: none; } }
    #home #homeBanner > div:first-child #scrollDown:hover {
      color: #526786; }
    #home #homeBanner > div:first-child #scrollDown .icon-stroke {
      margin-right: 5px;
      width: 1.4em;
      height: 1.4em;
      stroke-width: 2px; }

#home #bannerCycleLayer {
  position: relative;
  height: 100%; }
  @media screen and (max-width: 768px) {
    #home #bannerCycleLayer {
      height: auto; } }
  #home #bannerCycleLayer .link {
    position: absolute;
    left: 400px;
    right: 0;
    bottom: 0;
    height: auto;
    text-align: center;
    background: #fff;
    padding: 25px 0; }
    @media screen and (max-width: 1400px) {
      #home #bannerCycleLayer .link {
        left: 350px; } }
    @media screen and (max-width: 1200px) {
      #home #bannerCycleLayer .link {
        left: 0px;
        padding: 20px 30px;
        text-align: left; } }
    @media screen and (max-width: 768px) {
      #home #bannerCycleLayer .link {
        position: relative;
        left: auto;
        right: auto;
        bottom: auto;
        width: 100%;
        padding: 20px;
        background: #ececec; } }
    @media screen and (max-width: 600px) {
      #home #bannerCycleLayer .link {
        padding: 17px 20px; } }
    #home #bannerCycleLayer .link > span {
      position: relative;
      display: inline-block;
      padding-right: 50px; }
  #home #bannerCycleLayer #homeBannerPicCycle {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
    @media screen and (max-width: 768px) {
      #home #bannerCycleLayer #homeBannerPicCycle {
        position: relative;
        left: auto;
        right: auto;
        top: auto;
        bottom: auto;
        height: 300px; } }
    @media screen and (max-width: 768px) {
      #home #bannerCycleLayer #homeBannerPicCycle .link {
        position: absolute;
        left: 0;
        bottom: 0; } }
    @media screen and (max-width: 600px) {
      #home #bannerCycleLayer #homeBannerPicCycle {
        height: 240px; } }
    @media screen and (max-width: 500px) {
      #home #bannerCycleLayer #homeBannerPicCycle {
        height: 220px; } }
    #home #bannerCycleLayer #homeBannerPicCycle div {
      height: 100%;
      width: 100%;
      position: relative;
      overflow: hidden; }
      #home #bannerCycleLayer #homeBannerPicCycle div span.image {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: scale(1.1);
        transition: transform 1200ms cubic-bezier(0.23, 1, 0.32, 1) 600ms; }
      #home #bannerCycleLayer #homeBannerPicCycle div a {
        opacity: 0;
        transform: translate(0, 90px);
        transition: transform 600ms cubic-bezier(0.23, 1, 0.32, 1) 1200ms, opacity 600ms cubic-bezier(0.23, 1, 0.32, 1) 1200ms; }
    #home #bannerCycleLayer #homeBannerPicCycle .slick-current div span.image,
    #home #bannerCycleLayer #homeBannerPicCycle .slick-current-clone div span.image {
      transform: scale(1); }
    #home #bannerCycleLayer #homeBannerPicCycle .slick-current div a,
    #home #bannerCycleLayer #homeBannerPicCycle .slick-current-clone div a {
      opacity: 1;
      transform: translate(0, 0); }
  #home #bannerCycleLayer #homeBannerDescCycle {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 400px;
    min-height: 400px;
    padding-bottom: 40px;
    margin: 0;
    line-height: 360px;
    background: #252b44; }
    @media screen and (max-width: 1400px) {
      #home #bannerCycleLayer #homeBannerDescCycle {
        width: 350px;
        min-height: 350px;
        line-height: 310px; } }
    @media screen and (max-width: 1200px) {
      #home #bannerCycleLayer #homeBannerDescCycle {
        bottom: 60px;
        width: 100%;
        min-height: 0;
        padding-bottom: 0;
        line-height: normal; } }
    @media screen and (max-width: 768px) {
      #home #bannerCycleLayer #homeBannerDescCycle {
        position: relative;
        left: auto;
        bottom: auto;
        text-align: left; } }
    #home #bannerCycleLayer #homeBannerDescCycle .centerize {
      padding: 20px 50px;
      transform: translate(0, 30px);
      transition: transform 600ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
      @media screen and (max-width: 1400px) {
        #home #bannerCycleLayer #homeBannerDescCycle .centerize {
          padding: 20px 30px; } }
      @media screen and (max-width: 1200px) {
        #home #bannerCycleLayer #homeBannerDescCycle .centerize {
          padding: 30px; } }
      @media screen and (max-width: 768px) {
        #home #bannerCycleLayer #homeBannerDescCycle .centerize {
          padding: 30px 20px; } }
      #home #bannerCycleLayer #homeBannerDescCycle .centerize > :last-child {
        margin: 0; }
      #home #bannerCycleLayer #homeBannerDescCycle .centerize p {
        margin: 0 0 10px 0;
        color: #fff; }
      #home #bannerCycleLayer #homeBannerDescCycle .centerize h3 {
        margin-bottom: 20px;
        color: #fff; }
        @media screen and (max-width: 768px) {
          #home #bannerCycleLayer #homeBannerDescCycle .centerize h3 {
            margin-bottom: 10px; } }
    #home #bannerCycleLayer #homeBannerDescCycle .slick-current .centerize {
      transform: translate(0, 0); }
    #home #bannerCycleLayer #homeBannerDescCycle .slick-dots {
      bottom: 0;
      left: 0;
      height: 60px;
      padding: 0 50px;
      line-height: 60px;
      text-align: left; }
      @media screen and (max-width: 1200px) {
        #home #bannerCycleLayer #homeBannerDescCycle .slick-dots {
          right: 0;
          top: -40px;
          bottom: auto;
          height: 40px;
          width: auto;
          line-height: 40px;
          text-align: center; } }
      @media screen and (max-width: 768px) {
        #home #bannerCycleLayer #homeBannerDescCycle .slick-dots {
          display: none !important; } }
      #home #bannerCycleLayer #homeBannerDescCycle .slick-dots li {
        width: 10px;
        height: 10px;
        margin: 0 15px 0 0;
        line-height: 14px;
        vertical-align: middle;
        background: #526786;
        border-radius: 50%;
        transition: all 300ms; }
        @media screen and (max-width: 1200px) {
          #home #bannerCycleLayer #homeBannerDescCycle .slick-dots li {
            margin: 0 7px; } }
        #home #bannerCycleLayer #homeBannerDescCycle .slick-dots li button {
          display: none; }
        #home #bannerCycleLayer #homeBannerDescCycle .slick-dots li:hover {
          opacity: 0.5; }
        #home #bannerCycleLayer #homeBannerDescCycle .slick-dots li.slick-active {
          width: 14px;
          height: 14px;
          background: #fff;
          opacity: 1 !important; }

#home #gamme {
  position: relative;
  background: #fff;
  z-index: 1; }
  #home #gamme::before {
    position: absolute;
    left: 50%;
    right: 0;
    top: 0;
    bottom: 0;
    content: '';
    background: #ececec;
    z-index: -1; }
    @media screen and (max-width: 768px) {
      #home #gamme::before {
        display: none; } }
  #home #gamme > .col8 div {
    position: absolute;
    top: 50%;
    float: none;
    width: 33.333333%;
    text-align: center;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%); }
    @media screen and (max-width: 1600px) {
      #home #gamme > .col8 div {
        width: 41.66666%; } }
    @media screen and (max-width: 768px) {
      #home #gamme > .col8 div {
        position: relative;
        top: auto;
        width: auto;
        margin-bottom: 45px;
        transform: none;
        -webkit-transform: none; } }
  @media screen and (max-width: 768px) {
    #home #gamme #productsCycle {
      padding: 0 15px; } }
  @media screen and (max-width: 500px) {
    #home #gamme #productsCycle {
      padding: 0 0 0 15px; } }

#home #difference {
  position: relative;
  background: #fff;
  z-index: 1; }
  #home #difference #cta {
    position: relative;
    height: 800px;
    line-height: 800px; }
    @media screen and (max-width: 1600px) {
      #home #difference #cta {
        height: 700px;
        line-height: 700px; } }
    @media screen and (max-width: 1400px) {
      #home #difference #cta {
        height: 600px;
        line-height: 600px; } }
    @media screen and (max-width: 1200px) {
      #home #difference #cta {
        height: 500px;
        line-height: 500px; } }
    @media screen and (max-width: 1024px) {
      #home #difference #cta {
        height: 400px;
        line-height: 400px; } }
    @media screen and (max-width: 768px) {
      #home #difference #cta {
        line-height: 300px;
        text-align: center; } }
    @media screen and (max-width: 600px) {
      #home #difference #cta {
        height: 300px;
        line-height: 250px;
        text-align: left; } }
    #home #difference #cta::before {
      position: absolute;
      left: 0;
      top: 0;
      width: 200%;
      height: 100%;
      content: '';
      background: url("../../assets/images/content/difference.jpg") no-repeat center top;
      background-size: cover;
      z-index: -1; }
      @media screen and (max-width: 768px) {
        #home #difference #cta::before {
          width: 100%; } }
    #home #difference #cta h1 {
      color: #fff; }
  #home #difference #avantages {
    margin-top: 400px;
    line-height: normal;
    background: #252b44; }
    @media screen and (max-width: 1600px) {
      #home #difference #avantages {
        margin-top: 350px; } }
    @media screen and (max-width: 1400px) {
      #home #difference #avantages {
        margin-top: 250px; } }
    @media screen and (max-width: 1200px) {
      #home #difference #avantages {
        margin-top: 200px; } }
    @media screen and (max-width: 1024px) {
      #home #difference #avantages {
        margin-top: 150px; } }
    @media screen and (max-width: 768px) {
      #home #difference #avantages {
        margin: -100px 0 0 8.333333%;
        text-align: center; } }
    @media screen and (max-width: 600px) {
      #home #difference #avantages {
        width: calc(100% - 40px);
        margin: -50px 20px 0 20px;
        text-align: left; } }
    #home #difference #avantages h2 {
      color: #526786; }
    @media screen and (max-width: 768px) {
      #home #difference #avantages > div {
        padding-top: 40px;
        padding-bottom: 50px; } }
    @media screen and (max-width: 600px) {
      #home #difference #avantages > div {
        padding-top: 35px;
        padding-bottom: 40px; } }
    @media screen and (max-width: 768px) {
      #home #difference #avantages ul {
        display: inline-block;
        text-align: left; } }
    #home #difference #avantages ul li {
      position: relative;
      padding: 23px 0 23px 60px; }
      @media screen and (max-width: 1400px) {
        #home #difference #avantages ul li {
          padding: 20px 0 20px 60px; } }
      @media screen and (max-width: 1024px) {
        #home #difference #avantages ul li {
          padding: 15px 0 15px 50px; } }
      @media screen and (max-width: 768px) {
        #home #difference #avantages ul li {
          padding: 15px 0 15px 40px;
          margin-left: 40px; } }
      @media screen and (max-width: 600px) {
        #home #difference #avantages ul li {
          padding: 10px 0 10px 40px;
          margin-left: 0; } }
      #home #difference #avantages ul li:last-child {
        padding-bottom: 0; }
      #home #difference #avantages ul li span {
        position: absolute;
        left: 0;
        top: 37px;
        width: 30px;
        height: 30px;
        font-size: 22px;
        margin: 0 20px 0 0;
        background: #526786; }
        @media screen and (max-width: 1400px) {
          #home #difference #avantages ul li span {
            top: 27px; } }
        @media screen and (max-width: 1200px) {
          #home #difference #avantages ul li span {
            top: 25px; } }
        @media screen and (max-width: 1024px) {
          #home #difference #avantages ul li span {
            top: 21px;
            width: 25px;
            height: 25px;
            font-size: 18px; } }
        @media screen and (max-width: 768px) {
          #home #difference #avantages ul li span {
            width: 20px;
            height: 20px;
            font-size: 15px; } }
        @media screen and (max-width: 600px) {
          #home #difference #avantages ul li span {
            top: 13px; } }
        @media screen and (max-width: 500px) {
          #home #difference #avantages ul li span {
            top: 12px; } }
        #home #difference #avantages ul li span .icon-stroke {
          position: absolute;
          top: -5px;
          right: -5px;
          stroke: #fff; }
          @media screen and (max-width: 768px) {
            #home #difference #avantages ul li span .icon-stroke {
              top: -3px;
              right: -3px; } }
      #home #difference #avantages ul li h2 {
        margin: 0;
        line-height: 1.2;
        color: #fff; }
    #home #difference #avantages a {
      position: relative;
      display: block;
      padding: 40px 0;
      font: 24px/30px "Apercu-Medium";
      color: #fff;
      text-align: center;
      background: #526786;
      z-index: 1;
      transition: background 300ms; }
      @media screen and (max-width: 1200px) {
        #home #difference #avantages a {
          padding: 30px 0; } }
      @media screen and (max-width: 1024px) {
        #home #difference #avantages a {
          font-size: 20px; } }
      @media screen and (max-width: 768px) {
        #home #difference #avantages a {
          padding: 15px;
          font-size: 18px; } }
      @media screen and (max-width: 600px) {
        #home #difference #avantages a {
          padding: 12px 20px;
          font-size: 17px; } }
      #home #difference #avantages a:hover {
        background: #647692; }
      #home #difference #avantages a .icon-stroke {
        margin-right: 20px;
        stroke: #fff; }
        @media screen and (max-width: 600px) {
          #home #difference #avantages a .icon-stroke {
            margin-right: 10px; } }

#home #cta_carrieres {
  position: relative;
  padding-top: 0;
  background: #fff;
  z-index: 1; }
  #home #cta_carrieres::before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 38%;
    content: '';
    background: #ececec;
    z-index: -1; }
    @media screen and (max-width: 600px) {
      #home #cta_carrieres::before {
        display: none; } }

#home #homeNew {
  position: relative;
  z-index: 1; }
  @media screen and (max-width: 800px) {
    #home #homeNew {
      padding-bottom: 80px; } }
  @media screen and (max-width: 768px) {
    #home #homeNew {
      padding-bottom: 60px; } }
  @media screen and (max-width: 600px) {
    #home #homeNew {
      padding-top: 60px;
      padding-bottom: 60px; } }
  @media screen and (max-width: 500px) {
    #home #homeNew {
      padding-top: 50px;
      padding-bottom: 50px; } }
  #home #homeNew h1 {
    position: relative; }
    #home #homeNew h1 .btn {
      position: absolute;
      right: 0;
      bottom: 15px; }
      @media screen and (max-width: 1200px) {
        #home #homeNew h1 .btn {
          bottom: 12px; } }
      @media screen and (max-width: 1024px) {
        #home #homeNew h1 .btn {
          bottom: 10px; } }
      @media screen and (max-width: 800px) {
        #home #homeNew h1 .btn {
          display: none; } }
  @media screen and (max-width: 800px) {
    #home #homeNew .btn {
      margin-top: 30px; } }
  @media screen and (max-width: 600px) {
    #home #homeNew .btn {
      margin-top: 20px; } }

#home #cta_partenaires {
  margin-top: -200px;
  padding-top: 200px; }
  @media screen and (max-width: 1400px) {
    #home #cta_partenaires {
      margin-top: -150px;
      padding-top: 150px; } }
  @media screen and (max-width: 1200px) {
    #home #cta_partenaires {
      margin-top: -100px;
      padding-top: 100px; } }
  @media screen and (max-width: 800px) {
    #home #cta_partenaires {
      margin-top: 0;
      padding-top: 0; } }
  #home #cta_partenaires #scrollBack {
    display: block; }

#apropos #bannerScroll,
#generique #bannerScroll {
  margin-left: -5px; }

@media screen and (max-width: 600px) {
  #apropos #aproposCycleLayer,
  #generique #aproposCycleLayer {
    padding-left: 0; } }

#apropos #aproposCycle,
#generique #aproposCycle {
  position: relative; }
  @media screen and (max-width: 600px) {
    #apropos #aproposCycle,
    #generique #aproposCycle {
      padding: 0 20px; } }
  #apropos #aproposCycle .slick-list,
  #generique #aproposCycle .slick-list {
    padding-right: 320px; }
    @media screen and (max-width: 1600px) {
      #apropos #aproposCycle .slick-list,
      #generique #aproposCycle .slick-list {
        padding-right: 260px; } }
    @media screen and (max-width: 1400px) {
      #apropos #aproposCycle .slick-list,
      #generique #aproposCycle .slick-list {
        padding-right: 200px; } }
    @media screen and (max-width: 1200px) {
      #apropos #aproposCycle .slick-list,
      #generique #aproposCycle .slick-list {
        padding-right: 160px; } }
    @media screen and (max-width: 1024px) {
      #apropos #aproposCycle .slick-list,
      #generique #aproposCycle .slick-list {
        padding-right: 100px; } }
    @media screen and (max-width: 768px) {
      #apropos #aproposCycle .slick-list,
      #generique #aproposCycle .slick-list {
        padding-right: 50px; } }
    @media screen and (max-width: 600px) {
      #apropos #aproposCycle .slick-list,
      #generique #aproposCycle .slick-list {
        padding-right: 0; } }
    #apropos #aproposCycle .slick-list .slick-slide,
    #generique #aproposCycle .slick-list .slick-slide {
      padding-right: 50px; }
      @media screen and (max-width: 1600px) {
        #apropos #aproposCycle .slick-list .slick-slide,
        #generique #aproposCycle .slick-list .slick-slide {
          padding-right: 40px; } }
      @media screen and (max-width: 1400px) {
        #apropos #aproposCycle .slick-list .slick-slide,
        #generique #aproposCycle .slick-list .slick-slide {
          padding-right: 30px; } }
      @media screen and (max-width: 1200px) {
        #apropos #aproposCycle .slick-list .slick-slide,
        #generique #aproposCycle .slick-list .slick-slide {
          padding-right: 20px; } }
      @media screen and (max-width: 768px) {
        #apropos #aproposCycle .slick-list .slick-slide,
        #generique #aproposCycle .slick-list .slick-slide {
          padding-right: 10px; } }
      @media screen and (max-width: 600px) {
        #apropos #aproposCycle .slick-list .slick-slide,
        #generique #aproposCycle .slick-list .slick-slide {
          padding-right: 0; }
          #apropos #aproposCycle .slick-list .slick-slide > div,
          #generique #aproposCycle .slick-list .slick-slide > div {
            line-height: 0; }
            #apropos #aproposCycle .slick-list .slick-slide > div > div,
            #generique #aproposCycle .slick-list .slick-slide > div > div {
              height: 300px;
              line-height: 0;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center; }
              #apropos #aproposCycle .slick-list .slick-slide > div > div img,
              #generique #aproposCycle .slick-list .slick-slide > div > div img {
                display: none; } }
      @media screen and (max-width: 600px) and (max-width: 500px) {
        #apropos #aproposCycle .slick-list .slick-slide > div > div,
        #generique #aproposCycle .slick-list .slick-slide > div > div {
          height: 270px; } }
      #apropos #aproposCycle .slick-list .slick-slide img,
      #generique #aproposCycle .slick-list .slick-slide img {
        width: 100%; }
  #apropos #aproposCycle .slick-arrow,
  #generique #aproposCycle .slick-arrow {
    position: absolute;
    top: 0;
    right: 370px;
    z-index: 1;
    transform: none; }
    @media screen and (max-width: 1600px) {
      #apropos #aproposCycle .slick-arrow,
      #generique #aproposCycle .slick-arrow {
        right: 300px; } }
    @media screen and (max-width: 1400px) {
      #apropos #aproposCycle .slick-arrow,
      #generique #aproposCycle .slick-arrow {
        right: 230px; } }
    @media screen and (max-width: 1200px) {
      #apropos #aproposCycle .slick-arrow,
      #generique #aproposCycle .slick-arrow {
        right: 180px; } }
    @media screen and (max-width: 1024px) {
      #apropos #aproposCycle .slick-arrow,
      #generique #aproposCycle .slick-arrow {
        right: 120px; } }
    @media screen and (max-width: 768px) {
      #apropos #aproposCycle .slick-arrow,
      #generique #aproposCycle .slick-arrow {
        right: 60px; } }
    @media screen and (max-width: 600px) {
      #apropos #aproposCycle .slick-arrow,
      #generique #aproposCycle .slick-arrow {
        right: 20px; } }
    #apropos #aproposCycle .slick-arrow .icon-stroke,
    #generique #aproposCycle .slick-arrow .icon-stroke {
      stroke: #252b44; }
    #apropos #aproposCycle .slick-arrow:before,
    #generique #aproposCycle .slick-arrow:before {
      display: none; }
    #apropos #aproposCycle .slick-arrow.slick-prev,
    #generique #aproposCycle .slick-arrow.slick-prev {
      left: auto;
      right: 440px; }
      @media screen and (max-width: 1600px) {
        #apropos #aproposCycle .slick-arrow.slick-prev,
        #generique #aproposCycle .slick-arrow.slick-prev {
          right: 370px; } }
      @media screen and (max-width: 1400px) {
        #apropos #aproposCycle .slick-arrow.slick-prev,
        #generique #aproposCycle .slick-arrow.slick-prev {
          right: 290px; } }
      @media screen and (max-width: 1200px) {
        #apropos #aproposCycle .slick-arrow.slick-prev,
        #generique #aproposCycle .slick-arrow.slick-prev {
          right: 234px; } }
      @media screen and (max-width: 1024px) {
        #apropos #aproposCycle .slick-arrow.slick-prev,
        #generique #aproposCycle .slick-arrow.slick-prev {
          right: 174px; } }
      @media screen and (max-width: 768px) {
        #apropos #aproposCycle .slick-arrow.slick-prev,
        #generique #aproposCycle .slick-arrow.slick-prev {
          right: 114px; } }
      @media screen and (max-width: 600px) {
        #apropos #aproposCycle .slick-arrow.slick-prev,
        #generique #aproposCycle .slick-arrow.slick-prev {
          right: 74px; } }
      #apropos #aproposCycle .slick-arrow.slick-prev .icons-box,
      #generique #aproposCycle .slick-arrow.slick-prev .icons-box {
        transform: rotate(180deg); }

#apropos #scrollBack,
#generique #scrollBack {
  display: block; }

#sectionPages {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -35px;
  margin-left: -2%;
  margin-right: -2%; }
  #sectionPages .porteBox {
    width: 33%;
    padding: 0 2%;
    padding-bottom: 30px;
    position: relative;
    display: block;
    overflow: hidden;
    background: transparent; }
    @media screen and (max-width: 768px) {
      #sectionPages .porteBox {
        width: 50%; } }
    @media screen and (max-width: 600px) {
      #sectionPages .porteBox {
        width: 100%; } }
    #sectionPages .porteBox a {
      display: block;
      position: relative;
      overflow: hidden;
      height: 360px;
      background: white; }
      #sectionPages .porteBox a img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover; }
      #sectionPages .porteBox a::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #252b44;
        background: linear-gradient(0deg, #252b44 0%, #252b4400 100%);
        z-index: 1; }
    #sectionPages .porteBox::after {
      content: normal; }

#news #mainNav,
#prive #mainNav {
  background: #2F3755; }

#news #newsLayer .mainNew:nth-child(2),
#news #privateLayer .mainNew:nth-child(2),
#prive #newsLayer .mainNew:nth-child(2),
#prive #privateLayer .mainNew:nth-child(2) {
  margin-top: 20px; }

#filter {
  background: #252b44; }
  #filter #searchSection {
    color: white;
    padding-top: 45px; }
  #filter #desktopFilter ul {
    display: inline-block; }
    #filter #desktopFilter ul li {
      display: inline-block; }
      #filter #desktopFilter ul li a {
        position: relative;
        display: block;
        padding: 45px 25px;
        font: 13px/20px "Apercu-Medium";
        color: #526786;
        letter-spacing: 1px;
        text-transform: uppercase;
        transition: color 300ms;
        cursor: pointer; }
        @media screen and (max-width: 1600px) {
          #filter #desktopFilter ul li a {
            padding: 40px 20px; } }
        #filter #desktopFilter ul li a:hover {
          color: #fff; }
        #filter #desktopFilter ul li a::after {
          position: absolute;
          left: 25%;
          right: 25%;
          bottom: 0;
          height: 8px;
          content: '';
          background: #fff;
          opacity: 0;
          transition: opacity 500ms; }
      #filter #desktopFilter ul li.active a {
        color: #fff;
        cursor: default; }
        #filter #desktopFilter ul li.active a::after {
          opacity: 1; }
  #filter #mobilFilter {
    padding: 20px 0; }
    #filter #mobilFilter .select-field {
      max-width: 400px;
      margin: 0 auto; }

#newBanner {
  position: relative;
  background: #fff;
  z-index: 1; }
  #newBanner::before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 40%;
    content: '';
    background: #ececec;
    z-index: -1; }
  #newBanner > .col24 {
    height: 600px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative; }
    @media screen and (max-width: 1600px) {
      #newBanner > .col24 {
        height: 500px; } }
    @media screen and (max-width: 1400px) {
      #newBanner > .col24 {
        height: 450px; } }
    @media screen and (max-width: 1200px) {
      #newBanner > .col24 {
        height: 400px; } }
    @media screen and (max-width: 1024px) {
      #newBanner > .col24 {
        height: 350px; } }
    @media screen and (max-width: 768px) {
      #newBanner > .col24 {
        height: 300px; } }
    @media screen and (max-width: 600px) {
      #newBanner > .col24 {
        height: 250px; } }
    #newBanner > .col24 .credit {
      color: white;
      background: rgba(0, 0, 0, 0.7);
      position: absolute;
      bottom: 0;
      right: 0;
      font: 16px/40px "Apercu-Medium";
      padding: 0 15px; }

#newContent {
  background: #fff; }

#carrieres #mainNav {
  background: #2F3755 !important; }

#carrieres .carriereLayer {
  position: relative;
  z-index: 1; }
  #carrieres .carriereLayer::before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 75px;
    content: '';
    background: #2F3755;
    z-index: -1; }
    @media screen and (max-width: 1024px) {
      #carrieres .carriereLayer::before {
        height: 65px; } }
    @media screen and (max-width: 768px) {
      #carrieres .carriereLayer::before {
        height: 60px; } }

#carriereFormRow #formLayer {
  width: 100%;
  max-width: 820px;
  margin: 0 auto;
  text-align: center; }
  @media screen and (max-width: 768px) {
    #carriereFormRow #formLayer {
      text-align: left; } }
  #carriereFormRow #formLayer h2 {
    margin-bottom: 20px; }
  #carriereFormRow #formLayer h5 {
    margin-bottom: 60px; }
    @media screen and (max-width: 1200px) {
      #carriereFormRow #formLayer h5 {
        margin-bottom: 40px; } }
    @media screen and (max-width: 768px) {
      #carriereFormRow #formLayer h5 {
        margin-bottom: 30px; } }
  #carriereFormRow #formLayer #emploiForm {
    max-width: 720px;
    margin: 0 auto; }
    #carriereFormRow #formLayer #emploiForm .col24 {
      text-align: left; }

#appRow {
  background: #ececec; }
  #appRow #emploiForm {
    width: 100%;
    max-width: 820px;
    margin: 0 auto;
    text-align: center; }
    #appRow #emploiForm .col24 {
      text-align: left; }

.no-job {
  background-color: white; }

#partenaires #mainNav {
  background: #2F3755; }

#partenaires #mobilFilter {
  display: flex;
  justify-content: center; }
  @media screen and (max-width: 600px) {
    #partenaires #mobilFilter {
      display: block; } }
  #partenaires #mobilFilter .select-field {
    margin: 0 0 0 30px; }
    #partenaires #mobilFilter .select-field:first-child {
      margin-left: 0; }
    @media screen and (max-width: 1024px) {
      #partenaires #mobilFilter .select-field {
        margin: 0 0 0 15px; } }
    @media screen and (max-width: 600px) {
      #partenaires #mobilFilter .select-field {
        margin: 0 auto; }
        #partenaires #mobilFilter .select-field:first-child {
          margin: 0 auto;
          margin-bottom: 15px; } }

#partenaires #partnersList li a.hasImage .centerize .image {
  background-color: white; }
  #partenaires #partnersList li a.hasImage .centerize .image img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    max-width: 80%;
    max-height: 80%; }

#portfolio #mainNav {
  background: #2F3755; }

#portfolio #scrollBack {
  display: block; }

#portfolio #galerie > div {
  float: left;
  width: calc(50% - 50px);
  margin: 0 0 100px 0;
  line-height: 0;
  background: gray; }
  @media screen and (max-width: 1600px) {
    #portfolio #galerie > div {
      width: calc(50% - 40px);
      margin-bottom: 80px; } }
  @media screen and (max-width: 1200px) {
    #portfolio #galerie > div {
      width: calc(50% - 30px);
      margin-bottom: 60px; } }
  @media screen and (max-width: 1024px) {
    #portfolio #galerie > div {
      width: calc(50% - 20px);
      margin-bottom: 40px; } }
  @media screen and (max-width: 768px) {
    #portfolio #galerie > div {
      width: calc(50% - 10px);
      margin-bottom: 20px; } }
  @media screen and (max-width: 500px) {
    #portfolio #galerie > div {
      float: none;
      width: 100%;
      margin-bottom: 15px; } }
  #portfolio #galerie > div:nth-child(odd) {
    clear: both; }
  #portfolio #galerie > div:nth-child(even) {
    float: right;
    margin: 100px 0 0 0; }
    @media screen and (max-width: 1600px) {
      #portfolio #galerie > div:nth-child(even) {
        margin-top: 80px; } }
    @media screen and (max-width: 1200px) {
      #portfolio #galerie > div:nth-child(even) {
        margin-top: 60px; } }
    @media screen and (max-width: 1024px) {
      #portfolio #galerie > div:nth-child(even) {
        margin-top: 40px; } }
    @media screen and (max-width: 768px) {
      #portfolio #galerie > div:nth-child(even) {
        margin-top: 20px; } }
    @media screen and (max-width: 500px) {
      #portfolio #galerie > div:nth-child(even) {
        float: none;
        margin-top: 0;
        margin-bottom: 15px; } }
  #portfolio #galerie > div > a {
    position: relative;
    display: block; }
    #portfolio #galerie > div > a .desc {
      position: absolute;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.6);
      color: white;
      line-height: normal;
      font-size: 16px;
      padding: 5px 12px; }
    #portfolio #galerie > div > a img {
      width: 100%; }

.loginContainer {
  width: 100%;
  max-width: 600px;
  background-color: #2F3755;
  padding: 45px 60px;
  margin: 0 auto; }
  .loginContainer h3 {
    color: white;
    margin-bottom: 30px; }

.btnContainer {
  margin-top: 45px; }

.resetPswdContainer {
  width: 100%;
  max-width: 400px;
  margin: 0 auto; }

.lexiqueFilter {
  margin-bottom: 30px; }
  .lexiqueFilter .filter {
    display: flex; }
    @media screen and (max-width: 900px) {
      .lexiqueFilter .filter {
        flex-wrap: wrap; } }
    .lexiqueFilter .filter a {
      display: block;
      color: #b9bec4;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.075em;
      padding: 3px 2px 16px 2px;
      margin: 0 3px 0 3px;
      width: 13px;
      border-bottom: 3px solid transparent;
      text-align: center;
      box-sizing: content-box;
      transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
      cursor: pointer; }
      .lexiqueFilter .filter a:hover, .lexiqueFilter .filter a.active {
        color: #252b44;
        border-bottom: 3px solid #252b44; }
      @media screen and (max-width: 900px) {
        .lexiqueFilter .filter a {
          padding: 10px;
          background-color: #252b44;
          color: white;
          margin-bottom: 6px;
          border: 0; }
          .lexiqueFilter .filter a:hover, .lexiqueFilter .filter a.active {
            background-color: #526786;
            color: white;
            border-bottom: 0; } }
      .lexiqueFilter .filter a.all {
        width: auto;
        margin-right: 35px; }
        @media screen and (max-width: 900px) {
          .lexiqueFilter .filter a.all {
            margin-right: 3px; } }
    .lexiqueFilter .filter ul {
      display: flex;
      flex-wrap: wrap; }

#recherche-component .recherche-results {
  background: white;
  padding: 60px; }
  #recherche-component .recherche-results mark {
    background-color: rgba(82, 103, 134, 0.4); }
  #recherche-component .recherche-results .recherche-result-content {
    display: block; }
    #recherche-component .recherche-results .recherche-result-content .recherche-result-title .recherche-result-badge {
      display: block;
      font-size: 18px; }
    #recherche-component .recherche-results .recherche-result-content .link {
      margin-bottom: 0; }
    #recherche-component .recherche-results .recherche-result-content:not(:last-child) {
      border-bottom: 1px solid #252b44;
      padding-bottom: 45px;
      margin-bottom: 45px; }

#contact #mainNav {
  position: absolute;
  left: 0;
  right: 0;
  top: 40px; }
  @media screen and (max-width: 1024px) {
    #contact #mainNav {
      position: relative;
      left: auto;
      right: auto;
      top: auto; } }

#coord {
  background: #2F3755; }
  @media screen and (max-width: 768px) {
    #coord {
      height: auto !important;
      padding-top: 60px;
      padding-bottom: 60px;
      line-height: normal !important; } }
  @media screen and (max-width: 500px) {
    #coord {
      padding-top: 50px;
      padding-bottom: 50px; } }
  #coord h1, #coord h3, #coord h5 {
    line-height: 1.1;
    color: #fff; }
  #coord p {
    line-height: 1.2;
    color: #fff; }
  #coord ul li {
    position: relative;
    margin: 0 0 20px 0;
    padding-left: 35px;
    color: #fff; }
    #coord ul li .icon-stroke {
      position: absolute;
      left: 0;
      top: 3px;
      stroke: #fff;
      stroke-width: 2px; }
    #coord ul li:first-child a {
      cursor: default; }
    #coord ul li:first-child .icon-stroke {
      top: 8px;
      width: 1.5em;
      height: 1.5em; }
      @media screen and (max-width: 1200px) {
        #coord ul li:first-child .icon-stroke {
          top: 5px; } }
    #coord ul li:last-child {
      margin-top: 50px;
      margin-bottom: 0; }
      #coord ul li:last-child h3, #coord ul li:last-child h5 {
        font-family: "Apercu-Bold";
        font-size: 22px;
        margin: 0 0 20px 0; }
      @media screen and (max-width: 768px) {
        #coord ul li:last-child .icon-stroke {
          top: 0; } }
    #coord ul li .mail {
      display: inline;
      font-family: "Apercu-Medium";
      box-shadow: inset 0px -10px 0px -1px rgba(165, 165, 165, 0.5);
      transition: box-shadow 300ms; }
      #coord ul li .mail:hover {
        box-shadow: inset 0px -16px 0px -1px rgba(165, 165, 165, 0.5); }
    #coord ul li .dynamic h2, #coord ul li .dynamic h3, #coord ul li .dynamic h4, #coord ul li .dynamic h5, #coord ul li .dynamic h6 {
      margin-bottom: 5px;
      color: #777c93;
      font: 12px / 15px "Apercu-Medium"; }
    #coord ul li .dynamic p {
      line-height: 1.6; }
      #coord ul li .dynamic p:last-child {
        margin: 0; }
      #coord ul li .dynamic p:nth-child(4) {
        margin-bottom: 25px; }
  #coord #contactBanner {
    position: relative;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; }
    @media screen and (max-width: 768px) {
      #coord #contactBanner {
        display: none; } }
    #coord #contactBanner #scrollToForm {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 35px 55px;
      font: 15px/20px "Apercu-Medium";
      text-align: center;
      background: #fff;
      transition: color 300ms; }
      #coord #contactBanner #scrollToForm:hover {
        color: #526786; }
      #coord #contactBanner #scrollToForm .icon-stroke {
        margin-right: 15px;
        width: 1.4em;
        height: 1.4em;
        stroke-width: 2px;
        vertical-align: -0.4em; }

#formWrapper {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  text-align: center; }
  @media screen and (max-width: 768px) {
    #formWrapper {
      text-align: left; } }
  #formWrapper h2 {
    margin-bottom: 50px; }
    @media screen and (max-width: 1400px) {
      #formWrapper h2 {
        margin-bottom: 40px; } }
    @media screen and (max-width: 1200px) {
      #formWrapper h2 {
        margin-bottom: 35px; } }
    @media screen and (max-width: 500px) {
      #formWrapper h2 {
        margin-bottom: 30px; } }

.stripe-loading-indicator {
  display: none; }
